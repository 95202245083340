import { Tooltip } from "@mui/material";
import countries from "i18n-iso-countries";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactSpoiler } from "react-simple-spoiler";
import "swiper/css";
import "swiper/css/pagination";
import NotificationsApi from "../../../api/notifications";
import SessionsApi from "../../../api/sessionApi";
import SpecialistApi from "../../../api/specialistApi";
import UsersApi from "../../../api/usersApi";
import { ReactComponent as ChevronDown } from "../../../assets/Profile/specialist/chevron-down.svg";
import { ReactComponent as EearthIcon } from "../../../assets/Profile/specialist/earh.svg";
import { ReactComponent as Favourite } from "../../../assets/Profile/specialist/favourite.svg";
import { ReactComponent as SpecializationIcon } from "../../../assets/Profile/specialist/global.svg";
import { ReactComponent as MessageIcon } from "../../../assets/Profile/specialist/message.svg";
import { ReactComponent as PlayBtnIcon } from "../../../assets/Profile/specialist/play-circle-video.svg";
import { ReactComponent as SessionsIcon } from "../../../assets/Profile/specialist/play-circle.svg";
import { ReactComponent as ReviewsIcon } from "../../../assets/Profile/specialist/reviews-icon.svg";
import { ReactComponent as GreenStar } from "../../../assets/Profile/specialist/star.svg";
import { ReactComponent as Token } from "../../../assets/Profile/specialist/token-icon.svg";
import { ReactComponent as TotalEducationIcon } from "../../../assets/Profile/specialist/total-education.svg";
import { ReactComponent as TotalFederationsIcon } from "../../../assets/Profile/specialist/total-federations.svg";
import { ReactComponent as TotalQualificationsIcon } from "../../../assets/Profile/specialist/total-qualifications.svg";
import { ReactComponent as VerifiedIcon } from "../../../assets/Profile/specialist/verify.svg";
import { ReactComponent as WorkBefore } from "../../../assets/Profile/specialist/work-before.svg";
import CircleAvatar from "../../../components/CircleAvatar/CircleAvatar";
import Loader from "../../../components/Loader/Loader";
import ModalWindow from "../../../components/ModalWindow/ModalWindow";
import RealTimeSessionModalContent from "../../../components/RealTimeSessionModalContent/RealTimeSessionModalContent";
import StandartButton from "../../../components/StandartButton/StandartButton";
import { ContextProvider } from "../../../contextProvider";
import strings from "../../../localization";
import { SpecialistLevels } from "../../../types/Subscription";
import { SessionComment, SessionPackage } from "../../../types/TypeSession";
import { Badge, SpecialistData } from "../../../types/TypeSpecialist";
import { TypeUpdatedUserInfo, TypeUserData } from "../../../types/TypeUsers";
import { calculateAge } from "../../../utils/setupProfileInfo";
import Notify from "../../../utils/toaster";
import SubscribeBtn from "../SubscribeBtn/SubscribeBtn";
import { EditSpecialistProfile } from "./EditSpecialistProfile/EditSpecialistProfile";
import EducationSection from "./EducationSection/EducationSection";
import PackageCard from "./Packages/PackageCard/PackageCard";
import Packages from "./Packages/Packages";
import PublishedPosts from "./PublishedPosts/PublishedPosts";
import AllReviews from "./ReviewsSection/AllReviews/AllReviews";
import ReviewsSection from "./ReviewsSection/ReviewsSection";
import s from "./SpecialistProfileContent.module.css";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

interface WorkTime {
  from: string;
  to: string;
}

interface ServerData {
  [key: string]: { time: string[]; switch: boolean };
}
export type CertificatesData = {
  _id: string;
  filename: string;
  type: string;
  size: number;
  link: string;
};

type KeyString = {
  [id: string]: SessionComment[];
};

type SpecialistProfileContentProps = {
  isEdit: boolean;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  updateData: () => void;
  specialistUpdateData: TypeUpdatedUserInfo;
  setSpecialistUpdateData: Dispatch<SetStateAction<TypeUpdatedUserInfo>>;
  specialistData: SpecialistData;
  allSpecializationData: {
    specializationValue: string;
    specializationLable: string;
    specialistData: SpecialistData;
  }[];
  setSelectedSpecialistData: Dispatch<
    SetStateAction<
      | {
          specializationValue: string;
          specializationLable: string;
          specialistData: SpecialistData;
        }
      | undefined
    >
  >;
  profileUserData: TypeUserData | null | undefined;
  setUserUpdateData: Dispatch<SetStateAction<TypeUpdatedUserInfo>>;
  userUpdateData: TypeUpdatedUserInfo;
  video: File | null;
  setVideo: Dispatch<SetStateAction<File | null>>;
  calendarIsConnected: boolean;
  setBookingModalIsOpen: Dispatch<SetStateAction<boolean>>;
  avatarFile: File | null;
  setAvatarFile: Dispatch<SetStateAction<File | null>>;
  projectId?: string;
};

const SpecialistProfileContent = ({
  isEdit,
  specialistUpdateData,
  setSpecialistUpdateData,
  specialistData,
  profileUserData,
  setUserUpdateData,
  userUpdateData,
  video,
  setVideo,
  calendarIsConnected,
  setIsEdit,
  updateData,
  setBookingModalIsOpen,
  setAvatarFile,
  avatarFile,
  allSpecializationData,
  setSelectedSpecialistData,
  projectId,
}: SpecialistProfileContentProps) => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const location = useLocation();
  const activeProfileTab =
    location.state && location.state.activeProfileTab
      ? location.state.activeProfileTab
      : undefined;
  const connectionString = process.env.REACT_APP_HTTP_CONNECTION_STRING;
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const { userData } = useContext(ContextProvider);
  const [commentsData, setCommentsData] = useState<KeyString>();
  const [actionLoaders, setActionLoaders] = useState({
    certificates: false,
    packages: false,
  });
  const [daysState, setDaysState] = useState([
    {
      dayValue: "Monday",
      daytitle: strings.scheduleMonday,
      isOpen: false,
      switch: false,
      workTime: [{ from: "", to: "" }],
    },
    {
      dayValue: "Tuesday",
      daytitle: strings.scheduleTuesday,
      isOpen: false,
      switch: false,
      workTime: [{ from: "", to: "" }],
    },
    {
      dayValue: "Wednesday",
      daytitle: strings.scheduleWednesday,
      isOpen: false,
      switch: false,
      workTime: [{ from: "", to: "" }],
    },
    {
      dayValue: "Thursday",
      daytitle: strings.scheduleThursday,
      isOpen: false,
      switch: false,
      workTime: [{ from: "", to: "" }],
    },
    {
      dayValue: "Friday",
      daytitle: strings.scheduleFriday,
      isOpen: false,
      switch: false,
      workTime: [{ from: "", to: "" }],
    },
    {
      dayValue: "Saturday",
      daytitle: strings.scheduleSaturday,
      isOpen: false,
      switch: false,
      workTime: [{ from: "", to: "" }],
    },
    {
      dayValue: "Sunday",
      daytitle: strings.scheduleSunday,
      isOpen: false,
      switch: false,
      workTime: [{ from: "", to: "" }],
    },
  ]);

  const [textHeight, setTextHeight] = useState({
    aboutMyself: 0,
    aboutTeraphy: 0,
  });

  const [activeTab, setActiveTab] = useState<
    "general" | "posts" | "packages" | "reviews"
  >(activeProfileTab ?? "general");
  const [videoPreviewImg, setVideoPreviewImg] = useState<string | undefined>();
  const [totalInfo, setTotalInfo] = useState<{
    reviewTotal?: number;
    sessionsTotal?: number;
  }>({});
  const [
    totalCurrentSpecializationSessions,
    setTotalCurrentSpecializationSessions,
  ] = useState<{ [id: string]: number }>();
  const introVideoId =
    specialistData && specialistData.introductionVideo
      ? specialistData.introductionVideo
      : null;

  const [packagesData, setPackagesData] = useState<{
    packagesData: SessionPackage[];
    overlaps: {
      day: string;
      overlapTime: {
        start: Date;
        end: Date;
      }[];
    }[];
  } | null>(null);
  const [realTimeError, setRealTimeError] = useState("");
  const [realTimeSessionModal, setRealTimeSessionModal] = useState(false);
  const [isFavouriteSpec, setIsFavouriteSpec] = useState<boolean | undefined>();
  const [alreadyWork, setAlreadyWork] = useState(false);
  const [badges, setBadges] = useState<Badge[] | undefined>();
  const aboutMyselfRef = useRef<HTMLParagraphElement | null>(null);
  const aboutTeraphyRef = useRef<HTMLParagraphElement | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);

  const coachLevels = {
    [SpecialistLevels.STUDENT]: strings.specLvlStudent,
    [SpecialistLevels.GRADUATED_STUDENT]: strings.specLvlGradtudent,
    [SpecialistLevels.LEVEL1]: strings.specLvl1,
    [SpecialistLevels.LEVEL2]: strings.specLvl2,
  };

  useEffect(() => {
    if (!aboutMyselfRef.current || !aboutTeraphyRef.current) return;
    setTextHeight({
      aboutMyself: aboutMyselfRef.current.offsetHeight + 10,
      aboutTeraphy: aboutTeraphyRef.current.offsetHeight + 10,
    });
  }, [aboutMyselfRef.current, aboutTeraphyRef.current]);

  useEffect(() => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const img = imgRef.current;

    if (!video || !canvas || !img) return;

    const handleLoadedMetadata = () => {
      video.currentTime = 0;
    };

    const handleTimeUpdate = () => {
      const context = canvas.getContext("2d");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      if (!context) return;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const dataURL = canvas.toDataURL();
      setVideoPreviewImg(dataURL);
      img.src = dataURL;
    };

    video.addEventListener("loadedmetadata", handleLoadedMetadata);
    video.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      video.removeEventListener("loadedmetadata", handleLoadedMetadata);
      video.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, []);

  const getSpecialistsSessionsAmount = async (specialistId?: string) => {
    if (!token || !profileUserData) return;
    const response = await SessionsApi.getSpecialistsSessionsAmount(
      profileUserData._id,
      specialistId
    );
    if (response.status && !specialistId) {
      setTotalInfo((prev) => ({ ...prev, sessionsTotal: response.amount }));
    }
    if (response.status && specialistId) {
      setTotalCurrentSpecializationSessions((prev) => ({
        ...prev,
        [specialistId]: response.amount,
      }));
    }
  };
  const getSpecialistsComments = async (specialistId?: string) => {
    if (!token || !profileUserData || !profileUserData._id || !specialistData)
      return;

    const commentsResponse = await SessionsApi.getSpecialistsComments(
      profileUserData._id,
      15,
      1,
      specialistId
    );

    if (!specialistId && commentsResponse.status) {
      setTotalInfo((prev) => ({
        ...prev,
        reviewTotal: commentsResponse.totalAmount,
      }));
    }
    if (commentsResponse.status && specialistId) {
      setCommentsData((prev) => ({
        ...prev,
        [specialistId]: commentsResponse.comments,
      }));
    }
  };
  const getSpecialistSessionPackages = async () => {
    if (!token || !profileUserData || !specialistData) {
      return setActionLoaders((prev) => ({ ...prev, packages: false }));
    }
    setActionLoaders((prev) => ({ ...prev, packages: true }));
    const response = await SessionsApi.getSpecialistSessionPackages(
      token,
      profileUserData._id,
      specialistData._id
    );

    if (response.status && response.packages && response.overlapDays) {
      const responseOverlaps = response.overlapDays[0].overlaps.map((item) => ({
        day: item.day,
        overlapTime: item.overlapTime,
      }));

      setPackagesData({
        packagesData: response.packages,
        overlaps: responseOverlaps,
      });
    }
    setActionLoaders((prev) => ({ ...prev, packages: false }));
    if (!response.status && response.message) {
      return Notify(response.message);
    }
  };
  const getAllSpecialistUserBadges = async (specialistUserId: string) => {
    const response = await SpecialistApi.getAllSpecialistUserBadges(
      specialistUserId
    );
    if (response.status) {
      setBadges(response.badges);
    }
  };
  useEffect(() => {
    if (!profileUserData) return;
    getAllSpecialistUserBadges(profileUserData._id);
    getSpecialistSessionPackages();
    getSpecialistsComments();
    getSpecialistsSessionsAmount();
    if (!specialistData) return;
    getSpecialistsSessionsAmount(specialistData._id);
    getSpecialistsComments(specialistData._id);
  }, [specialistData]);

  const connectGoogleCalendar = async () => {
    if (!token) return;
    const response = await NotificationsApi.generateCode(token);
    if (response.status && response.url) {
      window.open(response.url);
    }
  };

  const processServerData = (data: ServerData): void => {
    const newDaysState = daysState.map((day) => {
      const dayKey = day.dayValue.toLowerCase();
      const schedule = data[dayKey];

      if (schedule.time.includes("DayOff")) {
        return { ...day, isOpen: false, workTime: [] };
      }

      const workTime: WorkTime[] = schedule.time.map((time) => {
        const [from, to] = time.split("-");
        return { from, to };
      });

      return { ...day, switch: schedule.switch, workTime };
    });

    setDaysState(newDaysState);
  };

  useEffect(() => {
    if (!specialistData || !specialistData.schedule) return;

    processServerData(specialistData.schedule);
  }, [specialistData]);

  useEffect(() => {
    if (location.search.includes("packages")) {
      setActiveTab("packages");
    }
  }, [location]);

  const updateQueryParams = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("activeTab", "packages");
    queryParams.delete("oldParam");
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  const addFavouriteUser = async () => {
    if (!token || !userData || !profileUserData) return;
    setIsFavouriteSpec(true);
    const response = await UsersApi.addFavouriteUser(
      token,
      userData._id,
      profileUserData._id
    );
    if (!response.status) {
      setIsFavouriteSpec(false);
      response.message && Notify(response.message);
    }
  };

  const removeFavouriteUser = async () => {
    if (!token || !userData || !profileUserData) return;
    setIsFavouriteSpec(false);
    const response = await UsersApi.removeFavouriteUser(
      token,
      userData._id,
      profileUserData._id
    );
    if (!response.status) {
      setIsFavouriteSpec(true);
      response.message && Notify(response.message);
    }
  };
  useEffect(() => {
    const makeAsync = async () => {
      if (!token || !profileUserData) return;
      const checkWorkResponse = await UsersApi.checkIfUserWorkedWithSpecialist(
        token,
        profileUserData._id
      );
      const response = await UsersApi.checkIfUserIsFavourite(
        token,
        profileUserData._id
      );
      if (checkWorkResponse.status) {
        setAlreadyWork(checkWorkResponse.worked);
      }
      if (response.status) {
        setIsFavouriteSpec(response.isFavourite);
      }
    };
    makeAsync();
  }, [profileUserData]);

  const sendRealTimeSessionRequest = async (
    sessionSubject: string,
    setSubjectValue: Dispatch<SetStateAction<string>>,
    specialistId: string
  ) => {
    if (!token || !userData || !profileUserData) return { status: false };
    const payload = {
      specialistUserId: profileUserData._id,
      specialistId: specialistId,
      userId: userData?._id,
      subject: sessionSubject,
      projectId: projectId,
    };
    const response = await SpecialistApi.sendRealTimeSessionRequest(
      token,
      payload
    );

    if (!response.status && !response.notEnoughBalance) {
      setRealTimeError(response.message!);
      return {
        status: response.status,
        notEnoughBalance: response.notEnoughBalance,
      };
    }
    if (!response.status && response.notEnoughBalance && response.message) {
      return {
        status: response.status,
        notEnoughBalance: response.notEnoughBalance,
      };
    }

    if (response.status) {
      navigate("/global-chat", {
        state: {
          userId: profileUserData._id,
        },
      });
      setSubjectValue("");
      setRealTimeError("");
      setRealTimeSessionModal(false);
      return {
        status: response.status,
        notEnoughBalance: response.notEnoughBalance,
      };
    }
    return {
      status: response.status,
    };
  };

  return (
    <div
      className={isEdit ? s.editContainer : s.container}
      id="profile-content"
    >
      {isEdit ? (
        <EditSpecialistProfile
          profileUserData={profileUserData}
          setUserUpdateData={setUserUpdateData}
          userUpdateData={userUpdateData}
          updateData={updateData}
          calendarIsConnected={calendarIsConnected}
          specialistUpdateData={specialistUpdateData}
          specialistData={specialistData}
          setSpecialistUpdateData={setSpecialistUpdateData}
          setVideo={setVideo}
          daysState={daysState}
          setDaysState={setDaysState}
          video={video}
          setAvatarFile={setAvatarFile}
          processServerData={processServerData}
          avatarFile={avatarFile}
        />
      ) : (
        <div className={s.profileInfoBlock}>
          <div className={s.headBlock}>
            <div className={s.headBlockMainContent}>
              <div className={s.headNameBlock}>
                <div className={s.mobileAvatarBlock}>
                  <CircleAvatar
                    userId={profileUserData?._id || ""}
                    name={profileUserData?.name || ""}
                    surname={profileUserData?.surname || ""}
                    height="auto"
                    width="100%"
                    fontSize="78px"
                    marginRight="0"
                    isModalAvatar
                    borderRadius="20px"
                    hideNoAvtaarBlock
                    isPubcicData={userData?._id !== profileUserData?._id}
                  />
                </div>
                {badges && badges.length ? (
                  <div className={s.badgesBlock}>
                    {badges.map((item, index) => (
                      <Tooltip arrow title={item.title} key={index}>
                        <a href={item.url} target="_blank">
                          <img src={item.link} alt="" className={s.badge} />
                        </a>
                      </Tooltip>
                    ))}
                  </div>
                ) : null}
                <div className={s.nameBlock}>
                  <div className={s.nameTitleBlock}>
                    <span className={s.nameTitle}>
                      {profileUserData?.name} {profileUserData?.surname}
                    </span>
                    {profileUserData?._id !== userData?._id && (
                      <div className={s.workedStatusBlock}>
                        {alreadyWork && (
                          <Tooltip arrow title={strings.alreadyWorked}>
                            <WorkBefore width={20} height={20} />
                          </Tooltip>
                        )}
                        {isFavouriteSpec !== undefined && (
                          <Tooltip
                            title={
                              isFavouriteSpec
                                ? strings.removeFromFavourite
                                : strings.addToFavourite
                            }
                          >
                            <div
                              role={"button"}
                              onClick={
                                isFavouriteSpec
                                  ? () => removeFavouriteUser()
                                  : () => addFavouriteUser()
                              }
                              className={s.favouriteBtn}
                            >
                              <Favourite
                                className={
                                  isFavouriteSpec
                                    ? s.favouriteIcon
                                    : s.notFavouriteIcon
                                }
                              />
                            </div>
                          </Tooltip>
                        )}
                      </div>
                    )}
                  </div>
                  <span className={s.ageTitle}>
                    {calculateAge(new Date(profileUserData!.dateOfBirth!)) +
                      " " +
                      strings.ageAbbr}
                  </span>
                  <div className={s.rightMenuInfoBlock}>
                    <SpecializationIcon />
                    <span>
                      {allSpecializationData
                        ? allSpecializationData
                            .map(
                              (item) =>
                                item.specialistData.mainSpecializations[0].labels.find(
                                  (item) =>
                                    item.language === userData?.selectedLanguage
                                )?.text +
                                (item.specialistData.level &&
                                item.specialistData.level.length
                                  ? " " +
                                    coachLevels[
                                      item.specialistData.level
                                    ].toLowerCase()
                                  : "")
                            )
                            .join(", ")
                        : ""}
                    </span>
                  </div>
                  <div className={s.rightMenuInfoBlock}>
                    <MessageIcon
                      className={s.infoIcon}
                      width={15}
                      height={15}
                    />
                    <span style={{ color: "black" }}>
                      {profileUserData &&
                        profileUserData.preferredLanguages!.join(", ")}
                    </span>
                  </div>
                  <div className={s.rightMenuInfoBlock}>
                    <EearthIcon />
                    <span style={{ color: "black" }}>
                      {profileUserData && profileUserData.location}
                    </span>
                  </div>
                </div>
              </div>
              <div className={s.actionBlock}>
                {profileUserData?._id !== userData?._id && (
                  <div>
                    <div className={s.guestProfileActionBlock}>
                      {specialistData?.isVerified && (
                        <StandartButton
                          buttonTitle={strings.bookProfileBtn}
                          action={() => setBookingModalIsOpen(true)}
                          width="100%"
                        />
                      )}
                      <SubscribeBtn userId={profileUserData?._id!} />
                    </div>
                    {specialistData.receiveRealTimeOffers && (
                      <StandartButton
                        buttonTitle={strings.startNow}
                        action={() => setRealTimeSessionModal(true)}
                        width="100%"
                      />
                    )}
                  </div>
                )}

                {userData?._id === profileUserData?._id ||
                !profileUserData?._id ? (
                  <div className={s.myProfileActionBtn}>
                    <StandartButton
                      buttonTitle={strings.editProfileBtn}
                      action={() => setIsEdit(true)}
                      width="100%"
                    />
                  </div>
                ) : null}
              </div>
              {totalInfo.reviewTotal !== undefined && (
                <div className={s.totalInfoBlock}>
                  <span>{strings.reviewSpecProfile}</span>
                  <span>{totalInfo.reviewTotal}</span>
                </div>
              )}
              {totalInfo.sessionsTotal !== undefined && (
                <div className={s.totalInfoBlock}>
                  <span>{strings.totalSessions}</span>
                  <span>{totalInfo.sessionsTotal}</span>
                </div>
              )}
              {profileUserData &&
              profileUserData.briefDescription &&
              profileUserData.briefDescription.length ? (
                <div className={s.briefDescription}>
                  {profileUserData.briefDescription.find(
                    (el) => el.language === userData?.selectedLanguage
                  )?.text ?? profileUserData.briefDescription[0].text}
                </div>
              ) : null}
            </div>
            {profileUserData?._id === userData?._id &&
              allSpecializationData.length < 3 && (
                <div className={s.integrationsBlock}>
                  <h4 className={s.blockLable}>
                    {strings.addNewSpecialization}
                  </h4>
                  <div className={s.integrationsElement}>
                    <p className={s.integartionsElementTitle}>
                      {strings.newSpecializationDescription}
                    </p>

                    <StandartButton
                      buttonTitle={strings.addSpecialtyBtn}
                      action={() => navigate("/new-speciality")}
                      width="100%"
                      disabled={calendarIsConnected}
                    />
                  </div>
                </div>
              )}
            {profileUserData?._id === userData?._id && (
              <div className={s.integrationsBlock}>
                <h4 className={s.blockLable}>
                  {strings.intagrationsSpecProfile}
                </h4>
                <div className={s.integrationsElement}>
                  <p className={s.integartionsElementTitle}>
                    {strings.connectGoogleSpecProfile}
                  </p>
                  <Tooltip
                    title={
                      calendarIsConnected ? strings.connectGoogleTooltip : ""
                    }
                    placement="right"
                    arrow
                  >
                    <div
                      style={{
                        width: "100%",
                      }}
                    >
                      <StandartButton
                        buttonTitle={strings.connectGoogleBtnSpecProfile}
                        action={connectGoogleCalendar}
                        width="100%"
                        disabled={calendarIsConnected}
                      />
                    </div>
                  </Tooltip>
                </div>
              </div>
            )}
          </div>
          <div className={s.dynamiContentBlock}>
            <div className={s.tabsBlock}>
              {allSpecializationData.map((item, index) => (
                <div
                  className={
                    activeTab === "general" &&
                    specialistData!.mainSpecializations[0].specialization ===
                      item.specializationValue
                      ? s.activeTab
                      : s.notActiveTab
                  }
                  onClick={() => {
                    navigate(location.pathname, { replace: true });
                    setActiveTab("general");
                    setSelectedSpecialistData(
                      allSpecializationData.find(
                        (el) =>
                          el.specializationValue === item.specializationValue
                      )
                    );
                  }}
                  key={index}
                >
                  <span className={s.specialityTabTitle}>
                    {item.specializationLable}{" "}
                    {item.specialistData.isVerified && (
                      <VerifiedIcon
                        className={
                          specialistData._id === item.specialistData._id
                            ? s.verifyIcon
                            : s.notSelectVerifyIcon
                        }
                      />
                    )}
                  </span>
                  <span
                    className={
                      activeTab === "general" &&
                      specialistData!.mainSpecializations[0].specialization ===
                        item.specializationValue
                        ? s.tabActiveIndicator
                        : s.indicatorHidden
                    }
                  ></span>
                </div>
              ))}
              <div
                className={activeTab === "posts" ? s.activeTab : s.notActiveTab}
                onClick={() => {
                  navigate(location.pathname, { replace: true });
                  setActiveTab("posts");
                }}
              >
                <span>{strings.publishePosts}</span>
                <span
                  className={
                    activeTab === "posts"
                      ? s.tabActiveIndicator
                      : s.indicatorHidden
                  }
                ></span>
              </div>
              {userData?._id === profileUserData?._id && (
                <div
                  className={
                    activeTab === "packages" ? s.activeTab : s.notActiveTab
                  }
                  onClick={() => {
                    updateQueryParams();
                    setActiveTab("packages");
                  }}
                >
                  <span>{strings.sessionPackage}</span>
                  <span
                    className={
                      activeTab === "packages"
                        ? s.tabActiveIndicator
                        : s.indicatorHidden
                    }
                  ></span>
                </div>
              )}
              {totalInfo.reviewTotal && totalInfo.reviewTotal > 2 ? (
                <div
                  className={
                    activeTab === "reviews" ? s.activeTab : s.notActiveTab
                  }
                  onClick={() => {
                    setActiveTab("reviews");
                  }}
                >
                  <span>{strings.reviewSpecProfile}</span>
                  <span
                    className={
                      activeTab === "packages"
                        ? s.tabActiveIndicator
                        : s.indicatorHidden
                    }
                  ></span>
                </div>
              ) : null}
            </div>
            {activeTab === "general" ? (
              <div className={s.mainInfoBlock}>
                {
                  <div className={s.headRatingBlock}>
                    <div className={s.headRatingValueBlock}>
                      <Token width={25} height={25} />
                      <div>
                        <span className={s.numberInfoValue}>
                          {specialistData?.price}
                        </span>
                        <span>{strings.presSession}</span>
                      </div>
                    </div>
                    {commentsData &&
                    commentsData[specialistData._id] &&
                    specialistData &&
                    specialistData.rating ? (
                      <div className={s.headRatingValueBlock}>
                        <GreenStar width={25} height={25} />
                        <div>
                          <span className={s.numberInfoValue}>
                            {specialistData?.rating.toFixed(1)}
                          </span>
                          <span>{strings.ratingSpecProfile}</span>
                        </div>
                      </div>
                    ) : null}
                    {totalCurrentSpecializationSessions &&
                      totalCurrentSpecializationSessions[specialistData._id] !==
                        undefined && (
                        <div className={s.headRatingValueBlock}>
                          <SessionsIcon width={25} height={25} />
                          <div>
                            <span className={s.numberInfoValue}>
                              {
                                totalCurrentSpecializationSessions[
                                  specialistData._id
                                ]
                              }
                            </span>
                            <span>{strings.totalSessions}</span>
                          </div>
                        </div>
                      )}
                    {commentsData && commentsData[specialistData._id] && (
                      <div className={s.headRatingValueBlock}>
                        <ReviewsIcon width={25} height={25} />
                        <div>
                          <span className={s.numberInfoValue}>
                            {commentsData[specialistData._id].length}
                          </span>
                          <span>{strings.reviewSpecProfile}</span>
                        </div>
                      </div>
                    )}
                  </div>
                }

                <div className={s.headRatingBlock}>
                  {specialistData.educationCertificates &&
                  specialistData.educationCertificates.length ? (
                    <div className={s.totalCertificateBlock}>
                      <TotalEducationIcon width={25} height={25} />
                      <div>
                        <span className={s.totalCertificatesLable}>
                          {`${strings.totalEducation1} ${specialistData.educationCertificates.length} ${strings.totalEducation2}`}
                        </span>
                      </div>
                    </div>
                  ) : null}
                  {specialistData.federationCertificates &&
                  specialistData.federationCertificates.length ? (
                    <div className={s.totalCertificateBlock}>
                      <TotalFederationsIcon width={25} height={25} />
                      <div>
                        <span className={s.totalCertificatesLable}>
                          {`${strings.totalFederations1} ${specialistData.federationCertificates.length} ${strings.totalFederations2}`}
                        </span>
                      </div>
                    </div>
                  ) : null}
                  {specialistData.qualificationCertificates &&
                  specialistData.qualificationCertificates.length ? (
                    <div className={s.totalCertificateBlock}>
                      <TotalQualificationsIcon width={25} height={25} />
                      <div>
                        <span className={s.totalCertificatesLable}>
                          {`${strings.totalQualifications1} ${specialistData.qualificationCertificates.length} ${strings.totalQualifications2}`}
                        </span>
                      </div>
                    </div>
                  ) : null}
                </div>
                {specialistData.contributeToProfession &&
                specialistData.contributeToProfession.length ? (
                  <div className={s.aboutTeraphyBlock}>
                    <h4 className={s.blockLable}>
                      {strings.professionContribution}
                    </h4>

                    <p className={s.aboutTitle}>
                      {specialistData.contributeToProfession.find(
                        (el) => el.language === userData?.selectedLanguage
                      )?.text ?? specialistData.contributeToProfession[0].text}
                    </p>
                  </div>
                ) : null}
                <div className={s.subSpecializationBlock}>
                  <h4 className={s.blockLable}>{strings.subSpecLable}</h4>
                  <div className={s.subSpecializationList}>
                    {specialistData.subSpecializations.map((item, index) => (
                      <div className={s.subSpecElement} key={index}>
                        <span className={s.subSpecIndicator}></span>
                        <span className={s.subSpecTitle}>
                          {
                            item.labels.find(
                              (el) => el.language === userData?.selectedLanguage
                            )?.text
                          }
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
                {specialistData.tags &&
                specialistData.tags.filter((el) => el.level === 1).length ? (
                  <div className={s.workWithSection}>
                    <h4 className={s.blockLable}>{strings.tagsSpecProfile}</h4>
                    <div className={s.workWithList}>
                      {specialistData.tags
                        .filter((el) => el.level === 1)
                        .map((item, index) => (
                          <div className={s.workWithElement} key={index}>
                            {
                              item.labels.find(
                                (el) =>
                                  el.language === userData?.selectedLanguage
                              )?.text!
                            }
                          </div>
                        ))}
                    </div>
                  </div>
                ) : null}
                <div className={s.aboutVideoBlock}>
                  {introVideoId ? (
                    <div className={s.previewVideoBlock}>
                      <canvas ref={canvasRef} style={{ display: "none" }} />
                      <img
                        ref={imgRef}
                        src={videoPreviewImg}
                        className={s.previewVideoImg}
                      />
                      <PlayBtnIcon
                        className={s.playBtn}
                        onClick={() => setVideoModalOpen(true)}
                      />
                    </div>
                  ) : null}
                  {(specialistData?.aboutMyself &&
                    specialistData?.aboutMyself.length) ||
                  (specialistData?.aboutTherapy &&
                    specialistData?.aboutTherapy.length) ? (
                    <div className={s.aboutTitleBlock}>
                      <h4 className={s.blockLable}>
                        {strings.aboutMeSpecProfile}
                      </h4>
                      <ReactSpoiler
                        collapsedSize={
                          textHeight.aboutMyself > 260
                            ? 260
                            : textHeight.aboutMyself
                        }
                        toggleContainerStyle={{ height: "fit-content" }}
                        showMoreComponent={
                          <div
                            className={s.showMoreBtn}
                            style={{
                              display:
                                textHeight.aboutMyself > 260 ? "flex" : "none",
                            }}
                          >
                            <p>{strings.showMore}</p> <ChevronDown />
                          </div>
                        }
                        showLessComponent={
                          <div
                            className={s.showMoreBtn}
                            style={{
                              display:
                                textHeight.aboutMyself > 260 ? "flex" : "none",
                            }}
                          >
                            <p>{strings.showLess}</p>
                            <ChevronDown className={s.chevronRotate} />
                          </div>
                        }
                      >
                        <p className={s.aboutTitle} ref={aboutMyselfRef}>
                          {specialistData?.aboutMyself &&
                          specialistData?.aboutMyself.length
                            ? specialistData?.aboutMyself.find(
                                (el) =>
                                  el.language === userData?.selectedLanguage
                              )?.text ?? specialistData?.aboutMyself[0].text
                            : specialistData?.aboutTherapy &&
                              specialistData?.aboutTherapy.length &&
                              (specialistData?.aboutTherapy.find(
                                (el) =>
                                  el.language === userData?.selectedLanguage
                              )?.text ??
                                specialistData?.aboutTherapy[0].text)}
                        </p>
                      </ReactSpoiler>
                    </div>
                  ) : null}
                </div>

                {specialistData.aboutTherapy &&
                specialistData.aboutTherapy.length &&
                specialistData.aboutMyself &&
                specialistData.aboutMyself.length ? (
                  <div className={s.aboutTeraphyBlock}>
                    <h4 className={s.blockLable}>
                      {strings.howSessionGoesSpecProfile}
                    </h4>
                    <ReactSpoiler
                      collapsedSize={
                        textHeight.aboutTeraphy > 290
                          ? 300
                          : textHeight.aboutTeraphy
                      }
                      showMoreComponent={
                        <div
                          className={s.showMoreBtn}
                          style={{
                            display:
                              textHeight.aboutTeraphy > 250 ? "flex" : "none",
                          }}
                        >
                          <p>{strings.showMore}</p> <ChevronDown />
                        </div>
                      }
                      showLessComponent={
                        <div
                          className={s.showMoreBtn}
                          style={{
                            display:
                              textHeight.aboutTeraphy > 250 ? "flex" : "none",
                          }}
                        >
                          <p>{strings.showLess}</p>
                          <ChevronDown className={s.chevronRotate} />
                        </div>
                      }
                    >
                      <p className={s.aboutTitle} ref={aboutTeraphyRef}>
                        {specialistData.aboutTherapy.find(
                          (el) => el.language === userData?.selectedLanguage
                        )?.text ?? specialistData.aboutTherapy[0].text}
                      </p>
                    </ReactSpoiler>
                  </div>
                ) : null}
                <div className={s.certificatesBlock}>
                  <EducationSection
                    specialistId={specialistData._id}
                    userId={profileUserData?._id!}
                  />
                </div>
                {packagesData && packagesData.packagesData.length ? (
                  actionLoaders.packages ? (
                    <div className={s.educationLoaderBlock}>
                      <Loader size={50} />
                    </div>
                  ) : (
                    <div className={s.packagesBlock}>
                      <h4 className={s.blockLable}>
                        {strings.sessionPackage +
                          ` (${packagesData.packagesData.length})`}
                      </h4>
                      <div className={s.packagesListBlock}>
                        {packagesData.packagesData.map((item, index) => (
                          <PackageCard
                            profileUserData={profileUserData}
                            sessionPackage={item}
                            overlaps={packagesData.overlaps}
                            setPackagesData={setPackagesData}
                            specialistData={specialistData}
                            key={index}
                          />
                        ))}
                      </div>
                    </div>
                  )
                ) : null}
                {commentsData && commentsData[specialistData._id] && (
                  <ReviewsSection
                    commentsData={commentsData[specialistData._id]}
                  />
                )}
              </div>
            ) : activeTab === "posts" ? (
              <PublishedPosts profileUserData={profileUserData} />
            ) : activeTab === "reviews" ? (
              <AllReviews userId={profileUserData!._id} />
            ) : (
              <Packages
                profileUserData={profileUserData}
                specialistData={specialistData}
              />
            )}
          </div>
        </div>
      )}
      <video
        ref={videoRef}
        src={`${connectionString}specialists/${
          profileUserData?._id === userData?._id
            ? "getSpecialistIntroVideo"
            : "getSpecialistPublicIntroVideo"
        }${
          profileUserData?._id === userData?._id
            ? "/" + profileUserData?._id
            : ""
        }/${specialistData?._id}`}
        style={{ display: "none" }}
        crossOrigin="anonymous"
      />

      <ModalWindow
        isOpen={videoModalOpen}
        setIsOpen={setVideoModalOpen}
        bgColor="transparent"
        width="fit-content"
      >
        <video
          src={`${connectionString}specialists/${
            profileUserData?._id === userData?._id
              ? "getSpecialistIntroVideo"
              : "getSpecialistPublicIntroVideo"
          }${
            profileUserData?._id === userData?._id
              ? "/" + profileUserData?._id
              : ""
          }/${specialistData?._id}`}
          crossOrigin="anonymous"
          controls
          autoPlay
          className={s.video}
        />
      </ModalWindow>
      {profileUserData && profileUserData.specialistIds && specialistData && (
        <ModalWindow
          isOpen={realTimeSessionModal}
          setIsOpen={setRealTimeSessionModal}
          width="fit-content"
        >
          <RealTimeSessionModalContent
            allSpecializations={profileUserData.specialistIds.map((item) => ({
              specialistId: item._id,
              price: item.price!,
              lable:
                item.mainSpecializations![0].labels.find(
                  (el) => el.language === userData?.selectedLanguage
                )?.text ?? item.mainSpecializations[0].specialization,
            }))}
            initialSpecialization={specialistData._id}
            fullName={profileUserData.name + " " + profileUserData.surname}
            price={specialistData.price!}
            realTimeError={realTimeError}
            sendRequestHandler={sendRealTimeSessionRequest}
            specialistUserId={profileUserData._id}
          />
        </ModalWindow>
      )}
    </div>
  );
};

export default SpecialistProfileContent;
