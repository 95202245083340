import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { TableFooter, TablePagination } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { styled, useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useState } from "react";
import TodosAPI from "../../api/todoApi";
import { ReactComponent as DeleteIcon } from "../../assets/Notes/delete.svg";
import { ReactComponent as EditIcon } from "../../assets/Notes/edit.svg";
import { ReactComponent as SortIcon } from "../../assets/Todo/sortIcon.svg";
import strings from "../../localization";
import { TodoList } from "../../types/TypeToDo";
import { formateDate } from "../../utils/general";
import CircleAvatar from "../CircleAvatar/CircleAvatar";
import Input from "../Input/Input";
import s from "./TodoListTable.module.css";

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        className={s.mobileHidden}
      >
        {theme.direction === "rtl" ? (
          <LastPageIcon className={s.paginationIcon} />
        ) : (
          <FirstPageIcon className={s.paginationIcon} />
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight color="success" className={s.paginationIcon} />
        ) : (
          <KeyboardArrowLeft color="success" className={s.paginationIcon} />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft color="success" className={s.paginationIcon} />
        ) : (
          <KeyboardArrowRight color="success" className={s.paginationIcon} />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        className={s.mobileHidden}
      >
        {theme.direction === "rtl" ? (
          <FirstPageIcon color="success" className={s.paginationIcon} />
        ) : (
          <LastPageIcon color="success" className={s.paginationIcon} />
        )}
      </IconButton>
    </Box>
  );
}

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "black",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F0F3F2",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#FFFFFF",
    border: 0,
  },
  // hide last border
  "td, th": {
    border: 0,
  },
}));

type RowsType = {
  _id: string;
  title: string;
  for: { _id: string; name: string; surname: string };
  createdBy: { _id: string; name: string; surname: string };
  date: Date | string;
};
type SpecialistTableProps = {
  rows: RowsType[];
  page: number;
  rowsPerPage: number;
  selectTodoListHandler: (todoListId: string) => void;
  setPagination: React.Dispatch<
    React.SetStateAction<{ page: number; rowPerPage: number; total: number }>
  >;
  setModalConfirm: React.Dispatch<React.SetStateAction<boolean>>;
  setIdForRemove: React.Dispatch<React.SetStateAction<string | null>>;
  count: number;
  setTodosData: React.Dispatch<React.SetStateAction<TodoList[] | null>>;
  setSortData: React.Dispatch<
    React.SetStateAction<{
      sortVector: 1 | -1 | undefined;
      sortField: "date" | "title";
    }>
  >;
  sortData: { sortVector: 1 | -1 | undefined; sortField: "date" | "title" };
};
export default function TodoListTable({
  rows,
  page,
  rowsPerPage,
  setPagination,
  count,
  selectTodoListHandler,
  setModalConfirm,
  setIdForRemove,
  setTodosData,
  sortData,
  setSortData,
}: SpecialistTableProps) {
  const token = localStorage.getItem("token");
  const [updateTodoList, setUpdateTodoList] = React.useState("");
  const [isEdit, setIsEdit] = useState<string | null>(null);

  const updateTodoListHandler = async (
    todoListId: string,
    rowTitle: string
  ) => {
    if (token && updateTodoList !== rowTitle) {
      const response = await TodosAPI.updateTodoList(token, {
        _id: todoListId,
        title: updateTodoList,
      });

      if (response.status) {
        setTodosData((prevTodoData) => {
          if (prevTodoData) {
            const updatedTodoListArray: TodoList[] = prevTodoData.map(
              (todo) => {
                if (todo._id === todoListId) {
                  return { ...todo, title: updateTodoList };
                }
                return todo;
              }
            );
            return updatedTodoListArray;
          }
          return null;
        });
      }
    }
    setIsEdit(null);
  };

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPagination((prev) => ({
      ...prev,
      rowPerPage: parseInt(event.target.value, 10),
    }));
    setPagination((prev) => ({ ...prev, page: 0 }));
  };

  return (
    <TableContainer
      component={Paper}
      className={s.tableContainer}
      sx={{
        minWidth: window.innerWidth > 768 ? 0 : "90svw",
        width: "100%",
        borderRadius: "20px",
        backgroundColor: "transparent",
        maxHeight:
          window.innerHeight > 500 ? "calc(100vh - 270px)" : "calc(200vh)",
      }}
    >
      <Table
        sx={{
          minWidth: window.innerWidth > 768 ? 0 : "90svw",
          width: "100%",
        }}
        aria-label="customized table"
        stickyHeader
      >
        <TableHead className={s.tableHead}>
          <TableRow>
            <StyledTableCell align="left">
              <div className={s.dateHeadBlock}>
                <span>{strings.titleTodoTable}</span>
                <SortIcon
                  className={s.sortIcon}
                  onClick={() =>
                    setSortData({
                      sortVector:
                        sortData.sortVector && sortData.sortVector > 0 ? -1 : 1,
                      sortField: "title",
                    })
                  }
                />
              </div>
            </StyledTableCell>
            <StyledTableCell align="left">
              {strings.createByTodoList}
            </StyledTableCell>
            <StyledTableCell
              align="left"
              className={`${s.nameColumn} ${s.crearedForMobile}`}
            >
              {strings.createForTodoList}
            </StyledTableCell>
            <StyledTableCell align="left" className={s.dateCell}>
              <div className={s.dateHeadBlock}>
                <span>{strings.dateTableTatile}</span>
                <SortIcon
                  className={s.sortIcon}
                  onClick={() =>
                    setSortData({
                      sortVector:
                        sortData.sortVector && sortData.sortVector > 0 ? -1 : 1,
                      sortField: "date",
                    })
                  }
                />
              </div>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow
              key={row._id}
              sx={{
                cursor: "pointer",
              }}
              onClick={() => (!isEdit ? selectTodoListHandler(row._id) : null)}
            >
              <StyledTableCell align="left" className={s.nameColumn}>
                {isEdit === row._id ? (
                  <Input
                    inputValue={updateTodoList}
                    isVisible
                    required
                    onChangeInput={(value) => setUpdateTodoList(value)}
                    bgColor="#c9c9c9"
                  />
                ) : (
                  row.title
                )}
              </StyledTableCell>
              <StyledTableCell align="left" className={s.nameColumn}>
                <div className={s.nameBlock}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CircleAvatar
                      userId={row.createdBy._id}
                      height="40px"
                      width="40px"
                      fontSize="20px"
                    />
                    {row.createdBy.name + " " + row.createdBy.surname}
                  </div>
                  <div className={s.mobileActionBlock}>
                    {isEdit === row._id ? (
                      <div
                        className={s.saveUpdateButton}
                        role="button"
                        onClick={() =>
                          updateTodoListHandler(row._id, row.title)
                        }
                      >
                        {strings.saveUpdateRogramBtn}
                      </div>
                    ) : (
                      <span
                        className={s.editButtonBlock}
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsEdit(row._id);
                          setUpdateTodoList(row.title);
                        }}
                      >
                        <EditIcon className={s.editIcon} />
                      </span>
                    )}
                    <DeleteIcon
                      className={s.deleteIcon}
                      onClick={(e) => {
                        e.stopPropagation();
                        setModalConfirm(true);
                        setIdForRemove(row._id);
                      }}
                    />
                  </div>
                </div>
              </StyledTableCell>
              <StyledTableCell
                align="left"
                className={`${s.nameColumn} ${s.crearedForMobile}`}
              >
                <div className={s.nameBlock}>
                  <CircleAvatar
                    userId={row.for._id}
                    height="40px"
                    width="40px"
                    fontSize="20px"
                  />
                  {row.for.name + " " + row.for.surname}
                </div>
              </StyledTableCell>
              <StyledTableCell align="left" className={s.dateColumn}>
                <div className={s.dateBlock}>
                  <span>{formateDate(new Date(row.date))}</span>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    {isEdit === row._id ? (
                      <div
                        className={s.saveUpdateButton}
                        role="button"
                        onClick={() =>
                          updateTodoListHandler(row._id, row.title)
                        }
                      >
                        {strings.saveUpdateRogramBtn}
                      </div>
                    ) : (
                      <EditIcon
                        className={s.editIcon}
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsEdit(row._id);
                          setUpdateTodoList(row.title);
                        }}
                      />
                    )}
                    <DeleteIcon
                      className={s.deleteIcon}
                      onClick={(e) => {
                        e.stopPropagation();
                        setModalConfirm(true);
                        setIdForRemove(row._id);
                      }}
                    />
                  </div>
                </div>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
        <TableFooter className={s.tableFooter}>
          <TableRow
            sx={{
              minWidth: 700,
              width: "100%",
              border: "none",
            }}
          >
            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              colSpan={4}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage={strings.rowPerPage}
              slotProps={{
                select: {
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                },
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              sx={{ border: "none", color: "black" }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
