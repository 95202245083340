import { ThemeProvider } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { DaySchedule } from "../../../utils/shedule";
import { theme } from "../../../utils/theme";
import StandartButton from "../../StandartButton/StandartButton";
import s from "./CopyScheduleMenu.module.css";

type CopyScheduleMenuProps = {
  allDaysState: DaySchedule[];
  setDaysState: Dispatch<SetStateAction<DaySchedule[]>>;
  selectedDay: string;
  setCopyMenuIsOpen: Dispatch<SetStateAction<number | boolean>>;
};

const CopyScheduleMenu = ({
  allDaysState,
  setDaysState,
  selectedDay,
  setCopyMenuIsOpen,
}: CopyScheduleMenuProps) => {
  const dayToCopy = allDaysState.find((el) => el.dayValue === selectedDay)!;
  const [daysForCopy, setDaysForCopy] = useState<string[]>([selectedDay]);
  const containerRef = useRef<any>(null);

  const handleClickOutside = (event: any) => {
    if (
      !containerRef.current.contains(event.target) &&
      !event.target.classList.contains(s.menuIcon)
    ) {
      setCopyMenuIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCopy = () => {
    const updatedSchedule = allDaysState.map((item) => {
      if (daysForCopy.includes(item.dayValue)) {
        return {
          ...item,
          switch: dayToCopy.switch,
          workTime: dayToCopy.workTime,
        };
      }
      return item;
    });
    setDaysState(updatedSchedule);
    setCopyMenuIsOpen(false);
  };

  return (
    <div className={s.container} ref={containerRef}>
      <span>Copy times to...</span>
      {allDaysState.map((item, index) => (
        <div key={index} className={s.dayElement}>
          <span>{item.daytitle}</span>{" "}
          <ThemeProvider theme={theme}>
            <Checkbox
              checked={daysForCopy.includes(item.dayValue)}
              inputProps={{ "aria-label": "controlled" }}
              color={"primary"}
              onClick={() =>
                daysForCopy.includes(item.dayValue)
                  ? setDaysForCopy((prev) =>
                      prev.filter((el) => el !== item.dayValue)
                    )
                  : setDaysForCopy((prev) => [...prev, item.dayValue])
              }
              disabled={selectedDay === item.dayValue}
            />{" "}
          </ThemeProvider>
        </div>
      ))}
      <div className={s.applyBlock}>
        <StandartButton
          action={handleCopy}
          buttonTitle={"Apply"}
          height={"35px"}
          width={"100%"}
        />
      </div>
    </div>
  );
};

export default CopyScheduleMenu;
