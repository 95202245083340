import { CircularProgress, ThemeProvider } from "@mui/material";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SpecialistApi from "../../api/specialistApi";
import { ReactComponent as ImportIcon } from "../../assets/Profile/specialist/import.svg";
import Input from "../../components/Input/Input";
import MultipleSelect from "../../components/MultipleSelect/MultipleSelect";
import StandartButton from "../../components/StandartButton/StandartButton";
import { ISO639LangReversed } from "../../constans/languagesList";
import { ContextProvider } from "../../contextProvider";
import strings from "../../localization";
import { theme } from "../../utils/theme";
import Notify from "../../utils/toaster";
import LangTabs from "../Profile/SpecialistProfileContent/EditSpecialistProfile/LangTabs/LangTabs";
import s from "./AddNewSpecialization.module.css";

type MainSpecialiszationsData = {
  value: string;
  lable: string;
};

type NewSpeciality = {
  _id?: string;
  mainSpecializations: string[];
  subSpecializations: string[];
  practiceHours: number | undefined;
  yearsOfExperience: number | undefined;
  aboutMyself: { text: string; language: string }[];
  aboutTherapy: { text: string; language: string }[];
  price: number | undefined;
};

const initialSpecData: NewSpeciality = {
  mainSpecializations: [],
  subSpecializations: [],
  practiceHours: undefined,
  yearsOfExperience: undefined,
  aboutMyself: [],
  aboutTherapy: [],
  price: undefined,
};

const AddNewSpecialization = () => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  const navigate = useNavigate();
  const [newSpeciality, setNewSpeciality] =
    useState<NewSpeciality>(initialSpecData);
  const [newAboutMyself, setNewAboutMyself] = useState<
    { text: string; language: string; selected: boolean }[] | undefined
  >();
  const [newAboutTeraphy, setNewAboutTeraphy] = useState<
    { text: string; language: string; selected: boolean }[] | undefined
  >();
  const [checkedSpecializations, setCheckedSpecializations] = useState<
    MainSpecialiszationsData[]
  >([]);
  const [mainSpecializationsData, setMainSpecializationsData] = useState<
    MainSpecialiszationsData[]
  >([]);
  const [video, setVideo] = useState<File | null>(null);

  const getSubSpecializations = async (mainSpec: string) => {
    if (token && userData) {
      const subSpecResponse = await SpecialistApi.getSubSpecializations(token, [
        mainSpec,
      ]);

      if (subSpecResponse.status && subSpecResponse.subSpecializations) {
        const subSpecData = subSpecResponse.subSpecializations.map((item) => ({
          value: item._id,
          lable: item.labels.find(
            (item) => item.language === userData?.selectedLanguage
          )?.text!,
        }));

        setCheckedSpecializations(subSpecData);
      }
    }
  };

  const addSpeciality = async () => {
    if (!userData || !token) return;
    const response = await SpecialistApi.addNewSpecialistProfileToUser(
      token,
      userData._id
    );

    if (response.status && response.specialistId) {
      const newSpecialityData = {
        ...newSpeciality,
        _id: response.specialistId,
      };

      const updateResponse = await SpecialistApi.update(
        newSpecialityData,
        token
      );

      if (updateResponse.status) {
        navigate(
          `/${userData.latinName}-${userData.latinSurname}-${userData._id.slice(
            -7
          )}`
        );
      }
    }
  };

  useEffect(() => {
    if (!newAboutMyself) return;
    const aboutMyselfToUpdate = newAboutMyself
      .filter((el) => el.text.length > 0)
      .map((item) => ({
        text: item.text,
        language: item.language,
      }));
    setNewSpeciality((prev) => ({
      ...prev,
      aboutMyself: aboutMyselfToUpdate,
    }));
  }, [newAboutMyself]);

  useEffect(() => {
    if (!newAboutTeraphy) return;
    const aboutTeraphyToUpdate = newAboutTeraphy
      .filter((el) => el.text.length > 0)
      .map((item) => ({
        text: item.text,
        language: item.language,
      }));
    setNewSpeciality((prev) => ({
      ...prev,
      aboutTherapy: aboutTeraphyToUpdate,
    }));
  }, [newAboutTeraphy]);

  useEffect(() => {
    if (
      !newSpeciality ||
      !newSpeciality.mainSpecializations ||
      !newSpeciality.mainSpecializations.length
    )
      return;
    getSubSpecializations(newSpeciality.mainSpecializations[0]);
  }, [newSpeciality, newSpeciality && newSpeciality.mainSpecializations]);

  useEffect(() => {
    if (!userData) return;
    const emptyText = userData.preferredLanguages!.map((item, index) => ({
      text: "",
      language: ISO639LangReversed[item],
      selected: index === 0,
    }));
    setNewAboutMyself(emptyText);
    setNewAboutTeraphy(emptyText);
  }, [userData]);

  useEffect(() => {
    const makeAsync = async () => {
      if (token && userData && userData.specialistIds) {
        const mainSpecializationsResponse =
          await SpecialistApi.getMainSpecializations(token);

        if (
          mainSpecializationsResponse.status &&
          mainSpecializationsResponse.mainSpecializations
        ) {
          const currentMainSpec = userData.specialistIds.map(
            (item) => item.mainSpecializations[0]
          );
          const availableMainSpecData =
            mainSpecializationsResponse.mainSpecializations.filter(
              (item1) =>
                !currentMainSpec.some((item2) => item1._id === item2._id)
            );

          setMainSpecializationsData(
            availableMainSpecData.map((item) => ({
              value: item._id,
              lable: item.labels.find(
                (item) => item.language === userData?.selectedLanguage
              )?.text!,
            }))
          );
        }
      }
    };
    makeAsync();
  }, [userData]);

  const handleVideoChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const videoElement = document.createElement("video");
      const fileReader = new FileReader();

      fileReader.onload = () => {
        videoElement.src = fileReader.result as string;
        videoElement.onloadedmetadata = () => {
          const aspectRatio =
            videoElement.videoHeight / videoElement.videoWidth;
          if (aspectRatio < 1) {
            Notify(strings.onlyVerticalVideo);
            setVideo(null);
          } else {
            setVideo(file); // Устанавливаем видео, если оно подходит
          }
        };
      };

      fileReader.readAsDataURL(file);
    } else {
      setVideo(null);
    }
  };

  const validateData = () => {
    if (
      !newSpeciality.mainSpecializations.length ||
      !newSpeciality.subSpecializations.length ||
      !newSpeciality.price
    ) {
      return false;
    }
    return true;
  };

  if (!newSpeciality) {
    return (
      <div className="loader-container">
        <ThemeProvider theme={theme}>
          <CircularProgress size={150} color="primary" />
        </ThemeProvider>
      </div>
    );
  }

  return (
    <div className={s.container}>
      <div className={s.leftProfileBlock}>
        <div className={s.headerEditBlock}>
          <div></div>

          <StandartButton
            buttonTitle={strings.profileSaveBtn}
            action={addSpeciality}
            tooltipTitle={strings.packageRequiredFieldsNotify}
            disabled={!validateData()}
          />

          {/* <button className={s.saveProfileButton} onClick={updateData}>
          <span>{strings.profileSaveBtn}</span>
        </button> */}
        </div>
        {newAboutMyself && (
          <div className={s.aboutWriteBlock}>
            <div className={s.headLableBlock}>
              <h3>{strings.aboutMeSpecProfile}</h3>
              <div className={s.charterCalc}>
                {newAboutMyself.find((el) => el.selected)?.text.length +
                  "/1500"}
              </div>
              {newAboutMyself.length > 1 && (
                <LangTabs
                  languagesData={newAboutMyself.map((item) => ({
                    language: item.language,
                    selected: item.selected,
                  }))}
                  onClickFunc={(lang) =>
                    setNewAboutMyself(
                      newAboutMyself.map((element) => {
                        if (element.language === lang) {
                          return {
                            ...element,
                            selected: true,
                          };
                        } else {
                          return {
                            ...element,
                            selected: false,
                          };
                        }
                      })
                    )
                  }
                />
              )}
            </div>
            <textarea
              name=""
              id=""
              cols={30}
              rows={10}
              value={newAboutMyself.find((el) => el.selected)?.text!}
              className={s.textAreaNewNote}
              onChange={(event) =>
                setNewAboutMyself((prev) => {
                  return prev
                    ? prev.map((item) => {
                        if (item.selected) {
                          return {
                            text:
                              event.target.value.length > 1500
                                ? item.text
                                : event.target.value,
                            language: item.language,
                            selected: true,
                          };
                        } else return item;
                      })
                    : undefined;
                })
              }
            ></textarea>
          </div>
        )}
        {newAboutTeraphy && (
          <div className={s.aboutWriteBlock}>
            <div className={s.headLableBlock}>
              {/*   <div> */}
              <h3>{strings.howSessionGoesSpecProfile}</h3>
              <div className={s.charterCalc}>
                {newAboutTeraphy.find((el) => el.selected)?.text.length +
                  "/1500"}
              </div>
              {/*      </div> */}
              {newAboutTeraphy.length > 1 && (
                <LangTabs
                  languagesData={newAboutTeraphy.map((item) => ({
                    language: item.language,
                    selected: item.selected,
                  }))}
                  onClickFunc={(lang) =>
                    setNewAboutTeraphy(
                      newAboutTeraphy.map((element) => {
                        if (element.language === lang) {
                          return {
                            ...element,
                            selected: true,
                          };
                        } else {
                          return {
                            ...element,
                            selected: false,
                          };
                        }
                      })
                    )
                  }
                />
              )}
            </div>
            <textarea
              name=""
              id=""
              cols={30}
              rows={10}
              value={newAboutTeraphy.find((el) => el.selected)?.text!}
              className={s.textAreaNewNote}
              onChange={(event) =>
                setNewAboutTeraphy((prev) => {
                  return prev
                    ? prev.map((item) => {
                        if (item.selected) {
                          return {
                            text:
                              event.target.value.length > 1500
                                ? item.text
                                : event.target.value,
                            language: item.language,
                            selected: true,
                          };
                        } else return item;
                      })
                    : undefined;
                })
              }
            ></textarea>
          </div>
        )}
        {/* <div className={s.tagsBlock}>
        <h3>{strings.tagsSpecProfile}</h3>
        <p className={s.blockDescriptionTitle}>{strings.selectTopics}</p>
        <ChangeSpecialistTags
          setSpecialistUpdateData={setSpecialistUpdateData}
          specialistData={specialistData}
        />
      </div> */}
      </div>
      <div className={s.rightProfileBlock}>
        <div className={s.professionalBlock}>
          <div className={s.inputBlock}>
            <span>
              {strings.presSession}
              <span className={s.required}>*</span>
            </span>
            <Input
              type="number"
              inputValue={
                newSpeciality.price ? newSpeciality.price.toString() : ""
              }
              isVisible
              required
              onChangeInput={(value) =>
                setNewSpeciality((prev) => ({ ...prev!, price: Number(value) }))
              }
            />
          </div>
          <div className={s.inputBlock}>
            <span>{strings.specProfileYearsExp}</span>
            <Input
              type="number"
              inputValue={
                newSpeciality.yearsOfExperience
                  ? newSpeciality.yearsOfExperience.toString()
                  : ""
              }
              isVisible
              required
              onChangeInput={(value) =>
                setNewSpeciality((prev) => ({
                  ...prev!,
                  yearsOfExperience: Number(value),
                }))
              }
            />
          </div>
          <div className={s.inputBlock}>
            <span>{strings.specProfilePracticeHours}</span>
            <Input
              type="number"
              inputValue={
                newSpeciality.practiceHours
                  ? newSpeciality.practiceHours.toString()
                  : ""
              }
              isVisible
              required
              onChangeInput={(value) =>
                setNewSpeciality((prev) => ({
                  ...prev!,
                  practiceHours: Number(value),
                }))
              }
            />
          </div>
          <div className={s.qualificationBlock}>
            <h2>{strings.qualification}</h2>
            <div className={s.multiSelectBlock}>
              <span>
                {strings.filterMainSpec} <span className={s.required}>*</span>
              </span>
              <MultipleSelect
                multiplie={false}
                data={mainSpecializationsData}
                setValue={(value) => {
                  setNewSpeciality((prev) => ({
                    ...prev!,
                    subSpecializations: [],
                    mainSpecializations: [value] as string[],
                  }));
                }}
                value={
                  newSpeciality.mainSpecializations
                    ? newSpeciality.mainSpecializations.toString()
                    : ""
                }
              />
            </div>
            <div className={s.multiSelectBlock}>
              <span>
                {strings.filterSubSpec}
                <span className={s.required}>*</span>
              </span>
              <MultipleSelect
                multiplie={true}
                data={checkedSpecializations}
                setValue={(value) =>
                  setNewSpeciality((prev) => ({
                    ...prev!,
                    subSpecializations: value as string[],
                  }))
                }
                disabled={
                  newSpeciality.mainSpecializations &&
                  newSpeciality.mainSpecializations.length
                    ? false
                    : true
                }
                value={newSpeciality.subSpecializations}
              />
            </div>
          </div>
        </div>
        <div className={s.videoBlock}>
          <h2>{strings.videoTitle}</h2>
          <label>
            <div className={s.editVideoIcon} role="button">
              <ImportIcon className={s.importIcon} />
              <span>{video ? video.name : "MP4"}</span>
            </div>
            <input
              style={{ display: "none" }}
              type="file"
              accept="video/*"
              onChange={(e) => handleVideoChange(e)}
            />
          </label>
        </div>
      </div>
    </div>
  );
};

export default AddNewSpecialization;
