import { useContext, useEffect, useState } from "react";
import SpecialistApi from "../../../../api/specialistApi";
import { ReactComponent as DeleteIcon } from "../../../../assets/SpecTools/delete.svg";
import { ReactComponent as PlusIcon } from "../../../../assets/SpecTools/plus.svg";
import AddCertificatesModal from "../../../../components/AddCertificatesModal/AddCertificatesModal";
import { ContextProvider } from "../../../../contextProvider";
import strings from "../../../../localization";
import {
  EducationCertificatesResponse,
  FederationsCertificatesResponse,
  QualificationCertificatesResponse,
} from "../../../../types/TypeSpecialist";
import { formatEducationPeriod } from "../../../../utils/dateActions";
import { SuccesNotify } from "../../../../utils/toaster";
import s from "../CreatingProfile.module.css";

type CertificatesData = {
  education: EducationCertificatesResponse[] | undefined;
  qualification: QualificationCertificatesResponse[] | undefined;
  federation: FederationsCertificatesResponse[] | undefined;
};

const SubSteps3 = () => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  const [certificatesData, setCertificatesData] = useState<CertificatesData>({
    education: undefined,
    qualification: undefined,
    federation: undefined,
  });
  const [qualificationModal, setQualificationModal] = useState(false);
  const [educationModal, setEducationModal] = useState(false);
  const [federationModal, setFederationModal] = useState(false);

  const maxCertificatesLength = 5;

  const getEducationCertificates = async () => {
    if (!token || !userData || !userData.specialistIds) return;
    const response = await SpecialistApi.getEducationCertificates(
      token,
      userData.specialistIds[0]._id,
      1,
      5
    );
    if (response.status && response.certificates) {
      setCertificatesData((prev) => ({
        ...prev,
        education: response.certificates,
      }));
    }
  };

  const getFederationCertificates = async () => {
    if (!token || !userData || !userData.specialistIds) return;

    const response = await SpecialistApi.getFederationCertificates(
      token,
      userData.specialistIds[0]._id,
      1,
      5
    );

    if (response.status && response.certificates) {
      setCertificatesData((prev) => ({
        ...prev,
        federation: response.certificates,
      }));
    }
  };

  const getQualificationCertificates = async () => {
    if (!token || !userData || !userData.specialistIds) return;
    const response = await SpecialistApi.getQualificationCertificates(
      token,
      userData.specialistIds[0]._id,
      1,
      5
    );
    if (response.status && response.certificates) {
      setCertificatesData((prev) => ({
        ...prev,
        qualification: response.certificates,
      }));
    }
  };
  useEffect(() => {
    getEducationCertificates();
    getFederationCertificates();
    getQualificationCertificates();
  }, []);

  const removeFederationCertificate = async (certificateIdToRemove: string) => {
    if (!token || !userData || !userData.specialistIds) return;
    const response = await SpecialistApi.removeFederationCertificate(
      token,
      certificateIdToRemove,
      userData.specialistIds[0]._id
    );

    if (response.status) {
      setCertificatesData((prev) => ({
        ...prev,
        federation: prev.federation!.filter(
          (el) => el._id !== certificateIdToRemove
        ),
      }));
      SuccesNotify(strings.certificateHasBeenRemoved);
    }
  };
  const removeEducationCertificate = async (certificateIdToRemove: string) => {
    if (!token || !userData || !userData.specialistIds) return;

    const response = await SpecialistApi.removeEducationCertificate(
      token,
      certificateIdToRemove,
      userData.specialistIds[0]._id
    );

    if (response.status) {
      setCertificatesData((prev) => ({
        ...prev,
        education: prev.education!.filter(
          (el) => el._id !== certificateIdToRemove
        ),
      }));
      SuccesNotify(strings.certificateHasBeenRemoved);
    }
  };
  const removeQualificationCertificate = async (
    certificateIdToRemove: string
  ) => {
    if (!token || !userData || !userData.specialistIds) return;
    const response = await SpecialistApi.removeQualificationCertificate(
      token,
      certificateIdToRemove,
      userData.specialistIds[0]._id
    );
    if (response.status) {
      setCertificatesData((prev) => ({
        ...prev,
        qualification: prev.qualification!.filter(
          (el) => el._id !== certificateIdToRemove
        ),
      }));
    }
    SuccesNotify(strings.certificateHasBeenRemoved);
  };

  return (
    <div className={s.subStepBlock}>
      <div className={s.certificatesBlock}>
        <div className={s.myEducationBlock}>
          <div className={s.newCertificateBlock}>
            <div className={s.textCertificatesBlock}>
              <h3>{strings.myEducationLable}</h3>
              <span className={s.certificateDescription}>
                {strings.certificateDescription}
              </span>
            </div>
            {!certificatesData.education ||
            (certificatesData.education &&
              certificatesData.education.length < maxCertificatesLength) ? (
              <div
                className={s.addCertificateBtn}
                onClick={() => setEducationModal(true)}
              >
                <PlusIcon className={s.plusIcon} />
                <span>{strings.addEducation}</span>
              </div>
            ) : null}
          </div>
          <div className={s.certificatesListBlock}>
            {certificatesData.education &&
              certificatesData.education.map((item, index) => (
                <div key={index} className={s.certificateElement}>
                  <div>
                    <span className={s.certificateNameTitle}>
                      {item.education.text},{" "}
                      <span className={s.secondTitle}>{item.description}</span>
                    </span>
                    <span className={s.secondTitle}>
                      {formatEducationPeriod(
                        new Date(item.membershipStart),
                        new Date(item.membershipEnd),
                        userData?.selectedLanguage!
                      )}
                    </span>
                  </div>
                  <div
                    onClick={() => {
                      removeEducationCertificate(item._id);
                    }}
                  >
                    <DeleteIcon className={s.deletIcon} />
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className={s.myQualificationBlock}>
          <div className={s.newCertificateBlock}>
            <div className={s.textCertificatesBlock}>
              <h3>{strings.qualificationLable}</h3>
              <span className={s.certificateDescription}>
                {strings.certificateDescription}
              </span>
            </div>
            {!certificatesData.qualification ||
            (certificatesData.qualification &&
              certificatesData.qualification.length < maxCertificatesLength) ? (
              <div
                className={s.addCertificateBtn}
                onClick={() => setQualificationModal(true)}
              >
                <PlusIcon className={s.plusIcon} />
                <span>{strings.addQualification}</span>
              </div>
            ) : null}
          </div>{" "}
          <div className={s.certificatesListBlock}>
            {certificatesData.qualification &&
              certificatesData.qualification.map((item, index) => (
                <div key={index} className={s.certificateElement}>
                  <div className={s.leftCertificateBlock}>
                    <img
                      src={item.qualification.badge.link}
                      alt=""
                      className={s.badgeIcon}
                    />
                    <div>
                      <span className={s.certificateNameTitle}>
                        {item.qualification.text}
                      </span>
                      <span className={s.secondTitle}>
                        {formatEducationPeriod(
                          new Date(item.membershipStart),
                          new Date(item.membershipEnd),
                          userData?.selectedLanguage!
                        )}
                      </span>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      removeQualificationCertificate(item._id);
                    }}
                  >
                    <DeleteIcon className={s.deletIcon} />
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className={s.federationsBlock}>
          <div className={s.newCertificateBlock}>
            <div className={s.textCertificatesBlock}>
              <h3>{strings.federationsLable}</h3>
              <span className={s.certificateDescription}>
                {strings.certificateDescription}
              </span>
            </div>
            {!certificatesData.federation ||
            (certificatesData.federation &&
              certificatesData.federation.length < maxCertificatesLength) ? (
              <div
                className={s.addCertificateBtn}
                onClick={() => setFederationModal(true)}
              >
                <PlusIcon className={s.plusIcon} />
                <span>{strings.addFederation}</span>
              </div>
            ) : null}
          </div>
          <div className={s.certificatesListBlock}>
            {certificatesData.federation &&
              certificatesData.federation.map((item, index) => (
                <div key={index} className={s.certificateElement}>
                  <div className={s.leftCertificateBlock}>
                    <img
                      src={item.federation.badge.link}
                      alt=""
                      className={s.badgeIcon}
                    />
                    <div>
                      <span className={s.certificateNameTitle}>
                        {item.federation.text}
                      </span>
                      <span className={s.secondTitle}>
                        {formatEducationPeriod(
                          new Date(item.membershipStart),
                          new Date(item.membershipEnd),
                          userData?.selectedLanguage!
                        )}
                      </span>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      removeFederationCertificate(item._id);
                    }}
                  >
                    <DeleteIcon className={s.deletIcon} />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <AddCertificatesModal
        certificateType="education"
        modalOpen={educationModal}
        setModalOpen={setEducationModal}
        specialization={userData!.specialistIds![0].mainSpecializations[0]._id!}
        specialistId={userData!.specialistIds![0]._id!}
        modalTitle={strings.addEducation}
        setNewCertificate={setCertificatesData}
      />
      <AddCertificatesModal
        certificateType="federation"
        modalOpen={federationModal}
        setModalOpen={setFederationModal}
        specialization={userData!.specialistIds![0].mainSpecializations[0]._id!}
        specialistId={userData!.specialistIds![0]._id!}
        modalTitle={strings.addFederation}
        setNewCertificate={setCertificatesData}
      />
      <AddCertificatesModal
        certificateType="qualification"
        modalOpen={qualificationModal}
        setModalOpen={setQualificationModal}
        specialization={userData!.specialistIds![0].mainSpecializations[0]._id!}
        specialistId={userData!.specialistIds![0]._id!}
        modalTitle={strings.addQualification}
        setNewCertificate={setCertificatesData}
      />
    </div>
  );
};

export default SubSteps3;
