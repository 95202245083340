import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { useContext, useEffect, useState } from "react";
import SpecialistApi from "../../../../api/specialistApi";
import Loader from "../../../../components/Loader/Loader";
import ModalWindow from "../../../../components/ModalWindow/ModalWindow";
import { ContextProvider } from "../../../../contextProvider";
import strings from "../../../../localization";
import {
  EducationCertificatesResponse,
  FederationsCertificatesResponse,
  QualificationCertificatesResponse,
} from "../../../../types/TypeSpecialist";
import EducationCard from "./EducationCard/EducationCard";
import s from "./EducationSection.module.css";
import FederationCard from "./FederationCard/FederationCard";
import QualificationCard from "./QualificationCard/QualificationCard";

type EducationSectionProps = {
  specialistId: string;
  userId: string;
};

type CertificatesData = {
  education: EducationCertificatesResponse[] | undefined;
  qualification: QualificationCertificatesResponse[] | undefined;
  federation: FederationsCertificatesResponse[] | undefined;
};

const EducationSection = ({ specialistId, userId }: EducationSectionProps) => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  const [certificatesData, setCertificatesData] = useState<CertificatesData>({
    education: undefined,
    qualification: undefined,
    federation: undefined,
  });

  const [previewFile, setPreviewFile] = useState<{
    pdfFile: string | null;
    imageFile: string | null;
  }>({ pdfFile: null, imageFile: null });
  const [previewFileModal, setPreviewFileModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const getEducationCertificates = async () => {
    if (!token) return;
    const response = await SpecialistApi.getEducationCertificates(
      token,
      specialistId,
      1,
      5
    );
    return { total: response.total, certificates: response.certificates };
  };
  const getEducationPublicCertificates = async () => {
    const response = await SpecialistApi.getEducationPublicCertificates(
      specialistId,
      1,
      5
    );

    return { total: response.total, certificates: response.certificates };
  };
  const getFederationCertificates = async () => {
    if (!token) return;
    const response = await SpecialistApi.getFederationCertificates(
      token,
      specialistId,
      1,
      5
    );
    return { total: response.total, certificates: response.certificates };
  };
  const getFederationPublicCertificates = async () => {
    const response = await SpecialistApi.getFederationPublicCertificates(
      specialistId,
      1,
      5
    );
    return { total: response.total, certificates: response.certificates };
  };
  const getQualificationCertificates = async () => {
    if (!token) return;
    const response = await SpecialistApi.getQualificationCertificates(
      token,
      specialistId,
      1,
      5
    );
    return { total: response.total, certificates: response.certificates };
  };
  const getQualificationPublicCertificates = async () => {
    const response = await SpecialistApi.getQualificationPublicCertificates(
      specialistId,
      1,
      5
    );
    return { total: response.total, certificates: response.certificates };
  };

  useEffect(() => {
    const makeAsync = async () => {
      setIsLoading(true);

      try {
        const getEducation =
          userData?._id === userId
            ? getEducationCertificates
            : getEducationPublicCertificates;

        const getFederation =
          userData?._id === userId
            ? getFederationCertificates
            : getFederationPublicCertificates;

        const getQualification =
          userData?._id === userId
            ? getQualificationCertificates
            : getQualificationPublicCertificates;
        const [educationData, federationData, qualificationData] =
          await Promise.all([
            getEducation(),
            getFederation(),
            getQualification(),
          ]);
        setCertificatesData({
          education: educationData?.certificates,
          federation: federationData?.certificates,
          qualification: qualificationData?.certificates,
        });
      } catch (error) {
        console.error("Error fetching certificates data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    makeAsync();
  }, [specialistId, userId]);

  useEffect(() => {
    setPreviewFile((prev) => ({ ...prev, imageFile: null }));
  }, []);
  const previewFileHandler = (link: string, type: string) => {
    if (type.includes("pdf")) {
      setPreviewFile({
        imageFile: null,
        pdfFile: link,
      });
    } else {
      setPreviewFile({
        imageFile: link,
        pdfFile: null,
      });
    }
    setPreviewFileModal(true);
  };

  if (isLoading) {
    return (
      <div className={s.loaderContainer}>
        <Loader size={100} />
      </div>
    );
  }
  if (
    !certificatesData.education &&
    !certificatesData.federation &&
    !certificatesData.qualification
  ) {
    return null;
  }
  return (
    <div className={s.container}>
      <div className={`${s.educationBlock} ${s.borderBottomBlock}`}>
        <span className={s.certificateTypeTitle}>{strings.education}</span>
        <div className={s.certificateListBlock}>
          {certificatesData.education &&
            certificatesData.education.map((item, index) => (
              <EducationCard
                educationData={item}
                previewFileHandler={previewFileHandler}
                key={index}
              />
            ))}
        </div>
      </div>
      <div className={`${s.educationBlock} ${s.borderBottomBlock}`}>
        <span className={s.certificateTypeTitle}>{strings.federation}</span>
        <div className={s.certificateListBlock}>
          {certificatesData.federation &&
            certificatesData.federation.map((item, index) => (
              <FederationCard
                federationData={item}
                previewFileHandler={previewFileHandler}
                key={index}
              />
            ))}
        </div>
      </div>
      <div className={s.educationBlock}>
        <span className={s.certificateTypeTitle}>
          {strings.qualificationCert}
        </span>
        <div className={s.certificateListBlock}>
          {certificatesData.qualification &&
            certificatesData.qualification.map((item, index) => (
              <QualificationCard
                qualificationData={item}
                previewFileHandler={previewFileHandler}
                key={index}
              />
            ))}
        </div>
      </div>

      <ModalWindow
        isOpen={previewFileModal}
        setIsOpen={setPreviewFileModal}
        bgColor="transparent"
        height="fit-content"
        width={"fit-content"}
        padding="0"
        maxWidth="fit-content"
      >
        <div
          className={
            previewFile.pdfFile ? s.pdfPreviewBlock : s.imagePreviewBlock
          }
        >
          {previewFile.pdfFile && (
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.js">
              <div className={s.pdfWorker}>
                <Viewer
                  fileUrl={previewFile.pdfFile}
                  plugins={[defaultLayoutPluginInstance]}
                />
              </div>
            </Worker>
          )}

          {previewFile.imageFile && (
            <img src={previewFile.imageFile} alt="" className={s.previewImg} />
          )}
        </div>
      </ModalWindow>
    </div>
  );
};

export default EducationSection;
