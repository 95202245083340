import dayjs, { Dayjs } from "dayjs";
import { Dispatch, SetStateAction, useState } from "react";
import SpecialistApi from "../../../api/specialistApi";
import EditIcon from "../../../assets/Profile/cloud-plus.svg";
import { ReactComponent as PlusIcon } from "../../../assets/Profile/specialist/add.svg";
import strings from "../../../localization";
import {
  Education,
  EducationCertificatesResponse,
  Federation,
  FederationsCertificatesResponse,
  Qualification,
  QualificationCertificatesResponse,
} from "../../../types/TypeSpecialist";
import { compressFiles } from "../../../utils/compressionFile";
import Notify, { SuccesNotify } from "../../../utils/toaster";
import Input from "../../Input/Input";
import MuiDatePicker from "../../MuiDatePicker/MuiDatePicker";
import MultipleSelect from "../../MultipleSelect/MultipleSelect";
import StandartButton from "../../StandartButton/StandartButton";
import s from "../AddCertificatesModal.module.css";

type CertificatesData = {
  education: EducationCertificatesResponse[] | undefined;
  qualification: QualificationCertificatesResponse[] | undefined;
  federation: FederationsCertificatesResponse[] | undefined;
};

type FederationsCertificates = {
  selectData: {
    education?: Education[];
    qualification?: Qualification[];
    federation?: Federation[];
  };
  setActionLoaders: Dispatch<SetStateAction<{ addedCertificate: boolean }>>;
  specialistId: string;
  specialization: string;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  setNewCertificate: Dispatch<SetStateAction<CertificatesData>>;
};

type NewQualification = {
  newQualification: {
    qualificationText: string | undefined;
    badge: {
      file: File | undefined;
      url: string | undefined;
      qualificationId: string | undefined;
    };
  };
  qualificationToSave: {
    file: File | undefined;
    qualificationId: string | undefined;
    membershipStart: Date | undefined;
    membershipEnd: Date | undefined;
    federationProfileUrl: string | undefined;
  };
};

const initialQualification: NewQualification = {
  newQualification: {
    qualificationText: undefined,
    badge: {
      file: undefined,
      url: undefined,
      qualificationId: undefined,
    },
  },
  qualificationToSave: {
    file: undefined,
    qualificationId: undefined,
    membershipStart: undefined,
    membershipEnd: undefined,
    federationProfileUrl: undefined,
  },
};

const QualificationsCertificates = ({
  selectData,
  setActionLoaders,
  specialistId,
  specialization,
  setModalOpen,
  setNewCertificate,
}: FederationsCertificates) => {
  const token = localStorage.getItem("token");
  const [selectValue, setSelectValue] = useState<string>("");
  const [addQualification, setAddQualification] =
    useState<NewQualification>(initialQualification);

  const validateURL = (url: string | undefined) => {
    if (!url) return false;
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  const handleCertificateChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target && event.target.files) {
      const file = event.target.files[0];
      if (file && file.type.includes("pdf")) {
        setAddQualification((prev) => ({
          ...prev,
          qualificationToSave: {
            ...prev.qualificationToSave,
            file: file,
          },
        }));
      }
      if (file && !file.type.includes("pdf")) {
        const compressedFile = await compressFiles(file);
        if (compressedFile.file) {
          setAddQualification((prev) => ({
            ...prev,
            qualificationToSave: {
              ...prev.qualificationToSave,
              file: compressedFile.file,
            },
          }));
        }
      }
    }
  };

  const handleBadgeIconChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target && event.target.files) {
      const file = event.target.files[0];

      if (file) {
        const compressedFile = await compressFiles(file);
        if (compressedFile.file) {
          setAddQualification((prev) => ({
            ...prev,
            newQualification: {
              ...prev.newQualification,
              badge: {
                ...prev.newQualification.badge,
                file: compressedFile.file,
              },
            },
          }));
        }
      }
    }
  };
  const createQualification = async (bacdgeId: string) => {
    if (!token) return;
    const payload = {
      qualificationText: addQualification.newQualification.qualificationText!,
      specialization: specialization,
      badgeId: bacdgeId,
    };
    const response = await SpecialistApi.createQualification(token, payload);
    return {
      status: response.status,
      qualificationId: response.qualification?._id,
    };
  };
  const createFederationBadge = async () => {
    if (!token) return;
    const formData = new FormData();
    formData.append("file", addQualification.newQualification.badge.file!);
    formData.append("url", addQualification.newQualification.badge.url!);
    const response = await SpecialistApi.createFederationBadge(token, formData);
    return { status: response.status, badgeId: response.badge?._id };
  };
  const saveFederation = async () => {
    if (!token) return;
    setActionLoaders((prev) => ({ ...prev, addedCertificate: true }));
    let badgeId: string | undefined = undefined;
    let qualificationId: string | undefined = undefined;
    if (selectValue === "other") {
      const badgeResponse = await createFederationBadge();
      badgeId = badgeResponse?.badgeId;
    }

    if (badgeId) {
      const createFederationResponse = await createQualification(badgeId);
      qualificationId = createFederationResponse?.qualificationId;
    }
    if (selectValue && selectValue !== "other") {
      qualificationId = selectValue;
    }
    if (qualificationId) {
      const formData = new FormData();
      formData.append("file", addQualification.qualificationToSave.file!);
      formData.append("qualificationId", qualificationId);
      formData.append(
        "membershipStart",
        addQualification.qualificationToSave.membershipStart!.toISOString()
      );
      formData.append(
        "membershipEnd",
        addQualification.qualificationToSave.membershipEnd!.toISOString()
      );
      formData.append(
        "federationProfileUrl",
        addQualification.qualificationToSave.federationProfileUrl!
      );
      const mainResponse = await SpecialistApi.uploadQualificationCertificates(
        specialistId,
        formData,
        token
      );
      if (mainResponse.status) {
        SuccesNotify(strings.federationSucces);
        setAddQualification(initialQualification);
        setModalOpen(false);
        setSelectValue("");
        setActionLoaders((prev) => ({ ...prev, addedCertificate: false }));
        setNewCertificate((prev) => ({
          ...prev,
          qualification: prev.qualification
            ? [...prev.qualification, mainResponse.certificate]
            : [mainResponse.certificate],
        }));
      }
      if (!mainResponse.status && mainResponse.message) {
        Notify(mainResponse.message);
        setActionLoaders((prev) => ({ ...prev, addedCertificate: false }));
      }
    }
  };
  const validationNewCertificate = () => {
    const qualificationToSave = addQualification.qualificationToSave;
    const newQualification = addQualification.newQualification;

    if (!selectValue) return false;
    if (
      !validateURL(qualificationToSave.federationProfileUrl) ||
      !qualificationToSave.membershipStart ||
      !qualificationToSave.membershipEnd ||
      !qualificationToSave.file
    )
      return false;
    if (
      selectValue === "other" &&
      (!newQualification.qualificationText ||
        !newQualification.badge.file ||
        !validateURL(newQualification.badge.url))
    )
      return false;
    return true;
  };

  return (
    <div className={`${s.container} ${s.federationContainer}`}>
      {selectData &&
        selectData.qualification &&
        (selectValue !== "other" ? (
          <MultipleSelect
            data={[
              ...(selectData.qualification.map((item) => ({
                lable: item.text,
                value: item._id,
              })) ?? []),
              { lable: strings.otherCertificate, value: "other" },
            ]}
            multiplie={false}
            setValue={(value) => setSelectValue(value as string)}
            value={selectValue}
            padding="10px"
            lable={strings.qualificationCert}
          />
        ) : (
          <div className={s.newFederationBlock}>
            <Input
              inputValue={
                addQualification.newQualification.qualificationText ?? ""
              }
              isVisible
              required
              onChangeInput={(value) =>
                setAddQualification((prev) => ({
                  ...prev,
                  newQualification: {
                    ...prev.newQualification,
                    qualificationText: value,
                  },
                }))
              }
              lable={strings.qualificationCert}
            />
            <Input
              inputValue={addQualification.newQualification.badge.url ?? ""}
              isVisible
              required
              onChangeInput={(value) =>
                setAddQualification((prev) => ({
                  ...prev,
                  newQualification: {
                    ...prev.newQualification,
                    badge: { ...prev.newQualification.badge, url: value },
                  },
                }))
              }
              lable={strings.badgeUrl}
              border={
                validateURL(addQualification.newQualification.badge.url) ||
                !addQualification.newQualification.badge.url ||
                !addQualification.newQualification.badge.url.length
                  ? "none"
                  : "1px solid red"
              }
              placeholder="https://www.credly.com/org/international-coach-federation/badge/icf-member-badge"
            />
            <div>
              <span className={s.secondLable}>{strings.badgeIcon}</span>
              <div className={s.uploadFilesActionBlock}>
                {addQualification.newQualification.badge.file && (
                  <img
                    src={URL.createObjectURL(
                      addQualification.newQualification.badge.file
                    )}
                    alt=""
                    className={s.badgeIcon}
                  />
                )}
                <label className={s.fileInputBlock}>
                  <input
                    style={{ display: "none" }}
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={handleBadgeIconChange}
                  />
                  {addQualification.newQualification.badge.file ? (
                    <span className={s.updateAvatarBtn} role="button">
                      <img src={EditIcon} alt="" />
                      <span>{strings.replace}</span>
                    </span>
                  ) : (
                    <div className={s.addCertificateBtn}>
                      <PlusIcon className={s.plusIcon} />
                      <span>{strings.addBadge}</span>
                    </div>
                  )}
                </label>
              </div>
            </div>
          </div>
        ))}
      <div className={s.inputBlock}>
        <Input
          inputValue={
            addQualification.qualificationToSave.federationProfileUrl ?? ""
          }
          isVisible
          required
          onChangeInput={(value) =>
            setAddQualification((prev) => ({
              ...prev,
              qualificationToSave: {
                ...prev.qualificationToSave,
                federationProfileUrl: value,
              },
            }))
          }
          lable={strings.federationProfileUrl}
          placeholder="https://apps.coachingfederation.org/eweb/CCFDynamicPage.aspx?webcode=ccfcoachprofileview&coachcstkey=A26D427B-86A3-4EE3-873E-D1468F4C48FB"
          border={
            validateURL(
              addQualification.qualificationToSave.federationProfileUrl
            ) ||
            !addQualification.qualificationToSave.federationProfileUrl ||
            !addQualification.qualificationToSave.federationProfileUrl.length
              ? "none"
              : "1px solid red"
          }
        />
      </div>
      <div className={s.dateBlock}>
        <span className={s.secondLable}>
          {strings.federationDatesDescription}
        </span>
        <div className={s.dateInputBlock}>
          <div className={s.dateInput}>
            <MuiDatePicker
              value={
                addQualification.qualificationToSave.membershipStart &&
                dayjs(addQualification.qualificationToSave.membershipStart)
              }
              setValue={(value: Dayjs | null) =>
                setAddQualification((prev) => ({
                  ...prev,
                  qualificationToSave: {
                    ...prev.qualificationToSave,
                    membershipStart: value?.toDate(),
                  },
                }))
              }
            />
          </div>
          <div className={s.dateInput}>
            <MuiDatePicker
              value={
                addQualification.qualificationToSave.membershipEnd &&
                dayjs(addQualification.qualificationToSave.membershipEnd)
              }
              setValue={(value: Dayjs | null) =>
                setAddQualification((prev) => ({
                  ...prev,
                  qualificationToSave: {
                    ...prev.qualificationToSave,
                    membershipEnd: value?.toDate(),
                  },
                }))
              }
            />
          </div>
        </div>
      </div>
      <div className={s.uploadCertificateBlock}>
        <span className={s.headLable}>{strings.certificatesTitle}</span>
        <span className={s.secondLable}>{strings.certificateDescr}</span>
        <div className={s.uploadFilesActionBlock}>
          {addQualification.qualificationToSave.file && (
            <div>{addQualification.qualificationToSave.file.name}</div>
          )}
          <label className={s.fileInputBlock}>
            <input
              style={{ display: "none" }}
              type="file"
              accept=".pdf, image/*"
              multiple
              onChange={handleCertificateChange}
            />
            {addQualification.qualificationToSave.file ? (
              <span className={s.updateAvatarBtn} role="button">
                <img src={EditIcon} alt="" />
                <span>{strings.replace}</span>
              </span>
            ) : (
              <div className={s.addCertificateBtn}>
                <PlusIcon />
                <span>{strings.addCertificate}</span>
              </div>
            )}
          </label>
        </div>
      </div>
      <StandartButton
        action={saveFederation}
        buttonTitle={strings.saveCertificateBtn}
        width="100%"
        disabled={!validationNewCertificate()}
      />
    </div>
  );
};

export default QualificationsCertificates;
