import { Skeleton, Tooltip } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ReactComponent as ViewFileIcon } from "../../../../../assets/Profile/specialist/view-file.svg";
import { ContextProvider } from "../../../../../contextProvider";
import strings from "../../../../../localization";
import { QualificationCertificatesResponse } from "../../../../../types/TypeSpecialist";
import { formatEducationPeriod } from "../../../../../utils/dateActions";
import { getPdfThumbnail } from "../../../../../utils/filesAction";
import s from "../EducationSection.module.css";

type EducationCardProps = {
  qualificationData: QualificationCertificatesResponse;
  previewFileHandler: (link: string, type: string) => void;
};

const QualificationCard = ({
  qualificationData,
  previewFileHandler,
}: EducationCardProps) => {
  const { userData } = useContext(ContextProvider);
  const [certificateThumbnail, setCertificateThumbnail] = useState<
    string | undefined
  >();
  const [thumbnailLoader, setThumbnailLoader] = useState(false);
  useEffect(() => {
    const loadThumbnail = async () => {
      if (qualificationData.type === "pdf") {
        setThumbnailLoader(true);
        const thumbnail = await getPdfThumbnail(qualificationData.link);
        setCertificateThumbnail(thumbnail);
        setThumbnailLoader(false);
      } else {
        setCertificateThumbnail(qualificationData.link);
      }
    };

    loadThumbnail();
  }, [qualificationData]);

  return (
    <div className={s.certificateElement}>
      {certificateThumbnail && !thumbnailLoader ? (
        <Tooltip
          arrow
          title={strings.showCertificate}
          style={{ fontSize: "20px" }}
        >
          <div
            onClick={() =>
              previewFileHandler(qualificationData.link, qualificationData.type)
            }
            className={s.showCertificateBtn}
          >
            <img
              src={certificateThumbnail}
              alt="PDF Thumbnail"
              className={s.thumbnailCertificate}
            />
          </div>
        </Tooltip>
      ) : (
        <Skeleton variant="rounded" width={"100%"} height={200} />
      )}
      <div className={s.mainInfoCertificateBlock}>
        <div className={s.headCertificateBlock}>
          <span>{qualificationData.qualification.text}</span>
        </div>
        <div className={s.bottomCertificateBlock}>
          <span>
            {formatEducationPeriod(
              new Date(qualificationData.membershipStart),
              new Date(qualificationData.membershipEnd),
              userData?.selectedLanguage!
            )}
          </span>
          <Tooltip arrow title={strings.showFederationProfile}>
            <a
              href={qualificationData.federationProfileUrl}
              className={s.showProfileBtn}
              target="_blank"
            >
              <ViewFileIcon />
            </a>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default QualificationCard;
