import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import SpecialistApi from "../../../../../api/specialistApi";
import { ReactComponent as CheckIcon } from "../../../../../assets/Profile/specialist/checked.svg";
import { ReactComponent as NotCheckIcon } from "../../../../../assets/Profile/specialist/not-checked.svg";
import { ContextProvider } from "../../../../../contextProvider";
import strings from "../../../../../localization";
import {
  SpecialistData,
  SpecialistTagsDirections,
} from "../../../../../types/TypeSpecialist";
import { TypeUpdatedUserInfo } from "../../../../../types/TypeUsers";
import s from "./ChangeSpecialistTags.module.css";

type DropDownMenuData = { lable: string; value: string; parentTag?: string };
type TagsData = {
  level1: DropDownMenuData[];
  level2: DropDownMenuData[];
  level3: DropDownMenuData[];
};

type ChangeSpecialistTagsProps = {
  setSpecialistUpdateData: Dispatch<SetStateAction<TypeUpdatedUserInfo>>;
  specialistData: SpecialistData | undefined;
};

const ChangeSpecialistTags = ({
  setSpecialistUpdateData,
  specialistData,
}: ChangeSpecialistTagsProps) => {
  const token = localStorage.getItem("token");
  const _ = require("lodash");
  const { userData } = useContext(ContextProvider);
  const [tags, setTags] = useState<TagsData>({
    level1: [],
    level2: [],
    level3: [],
  });
  const [selectedTags, setSelectedTags] = useState<{
    level1: { _id: string; parentTag?: string }[];
    level2: { _id: string; parentTag: string }[];
    level3: { _id: string; parentTag: string }[];
  }>({
    level1: [],
    level2: [],
    level3: [],
  });

  const directions: { [key: string]: SpecialistTagsDirections } = {
    ["Coach"]: SpecialistTagsDirections.COACHING,
    ["Psychologist"]: SpecialistTagsDirections.MENTORING,
    ["Mentor"]: SpecialistTagsDirections.PSYTHERAPHY,
  };
  const gatTags = async (
    level: number,
    keyForChange: keyof TagsData,
    parentTagsIds?: string[]
  ) => {
    if (!token || !specialistData || !userData) return;
    const payload = {
      level: level,
      direction:
        directions[specialistData.mainSpecializations[0].specialization],
      parentTagsIds: parentTagsIds,
    };

    const response = await SpecialistApi.getSpecialistTags(token, payload);

    if (response.status && response.tags) {
      const tagsData = response.tags.map((item) => ({
        lable: item.labels.find(
          (el) => el.language === userData.selectedLanguage
        )!.text,
        value: item._id,
        parentTag: item.parentTag,
      }));
      setTags((prev) => ({ ...prev, [keyForChange]: tagsData }));
    }
  };

  useEffect(() => {
    gatTags(1, "level1");
    if (!specialistData || !specialistData.tags || !specialistData.tags.length)
      return;
    const userTags = {
      level1: specialistData.tags
        .filter((el) => el.level === 1)
        .map((item) => ({ _id: item._id })),
      level2: specialistData.tags
        .filter((el) => el.level === 2)
        .map((item) => ({ _id: item._id, parentTag: item.parentTag! })),
      level3: specialistData.tags
        .filter((el) => el.level === 3)
        .map((item) => ({ _id: item._id, parentTag: item.parentTag! })),
    };

    setSelectedTags(userTags);
  }, [specialistData]);

  useEffect(() => {
    setSelectedTags((prev) => ({
      ...prev,
      level2: prev.level2.filter((el) =>
        prev.level1.map((item) => item._id).includes(el.parentTag)
      ),
    }));
    gatTags(
      2,
      "level2",
      selectedTags.level1.map((item) => item._id)
    );
  }, [selectedTags.level1]);

  useEffect(() => {
    setSelectedTags((prev) => ({
      ...prev,
      level3: prev.level3.filter((el) =>
        prev.level2.map((item) => item._id).includes(el.parentTag)
      ),
    }));
    gatTags(
      3,
      "level3",
      selectedTags.level2.map((item) => item._id)
    );
  }, [selectedTags.level2]);

  useEffect(() => {
    if (!specialistData) return;
    const newTags = Object.values(selectedTags)
      .flat()
      .map((item) => item._id);
    const oldTags = specialistData.tags.map((item) => item._id);
    if (
      _.isEqual(newTags, oldTags) ||
      !Object.values(selectedTags).flat().length
    )
      return;

    setSpecialistUpdateData((prev) => ({
      ...prev,
      tags: Object.values(selectedTags).flat(),
    }));
  }, [selectedTags]);

  if (!tags.level1 || !tags.level1.length) {
    return null;
  }

  const checkIfSelectedTag = (
    tagId: string,
    selectedTags: { _id: string; parentTag?: string }[]
  ) => {
    const arrAllTagsIds = selectedTags.map((item) => item._id);
    return arrAllTagsIds.includes(tagId);
  };

  return (
    <div className={s.container}>
      {/*  <div className={s.inputBlock}>
        <span className={s.inputLable}>
          {strings.diractionTagLable + " "}{" "}
          <span className={s.totalVariants}>({tags.level1.length})</span>
        </span>
        <MultipleSelect
          data={tags.level1}
          multiplie
          setValue={(value) => {
            setSelectedTags((prev) => ({
              ...prev,
              level1: (value as string[]).map((tagId) => ({
                _id: tagId,
              })),
            }));
          }}
          value={selectedTags.level1.map((item) => item._id)}
          chip
        />
      </div>
      <div className={s.inputBlock}>
        <span className={s.inputLable}>
          {strings.topicTagLable + " "}{" "}
          <span className={s.totalVariants}>({tags.level2.length})</span>
        </span>
        <MultipleSelect
          data={tags.level2}
          multiplie
          setValue={(value) =>
            setSelectedTags((prev) => ({
              ...prev,
              level2: (value as string[]).map((tagId) => ({
                _id: tagId,
                parentTag: tags.level2.find((el) => el.value === tagId)!
                  .parentTag!,
              })),
            }))
          }
          value={selectedTags.level2.map((item) => item._id)}
          disabled={!tags.level2.length}
          chip
        />
      </div>
      <div className={s.inputBlock}>
        <span className={s.inputLable}>
          {strings.resulTagLable + " "}
          <span className={s.totalVariants}>({tags.level3.length})</span>
        </span>
        <MultipleSelect
          data={tags.level3}
          multiplie
          setValue={(value) =>
            setSelectedTags((prev) => ({
              ...prev,
              level3: (value as string[]).map((tagId) => ({
                _id: tagId,
                parentTag: tags.level3.find((el) => el.value === tagId)!
                  .parentTag!,
              })),
            }))
          }
          value={selectedTags.level3.map((item) => item._id)}
          disabled={!tags.level3.length}
          chip
        />
      </div> */}
      <div className={s.actionBlock}>
        <div className={s.tagsBlock}>
          <div className={s.headTagBlock}>
            <span>{strings.direction}</span>
            <div className={s.totalVarinatsBlock}>
              {selectedTags.level1.length + " / " + tags.level1.length}
            </div>
          </div>
          <div className={s.tagsListBlock}>
            {tags.level1.map((item, index) => (
              <div
                key={index}
                className={s.tagElement}
                onClick={() =>
                  setSelectedTags((prev) => ({
                    ...prev,
                    level1: checkIfSelectedTag(item.value, selectedTags.level1)
                      ? prev.level1.filter((el) => el._id !== item.value)
                      : [...prev.level1, { _id: item.value }],
                  }))
                }
              >
                {checkIfSelectedTag(item.value, selectedTags.level1) ? (
                  <CheckIcon width={18} height={18} />
                ) : (
                  <NotCheckIcon width={18} height={18} />
                )}
                <span>{item.lable}</span>
              </div>
            ))}
          </div>
        </div>
        <div className={s.tagsBlock}>
          <div
            className={s.headTagBlock}
            style={{ opacity: tags.level3.length ? 1 : 0.5 }}
          >
            <span>{strings.topic}</span>
            <div className={s.totalVarinatsBlock}>
              {selectedTags.level2.length + " / " + tags.level2.length}
            </div>
          </div>
          <div className={s.tagsListBlock}>
            {tags.level2.map((item, index) => (
              <div
                key={index}
                className={s.tagElement}
                onClick={() =>
                  setSelectedTags((prev) => ({
                    ...prev,
                    level2: checkIfSelectedTag(item.value, selectedTags.level2)
                      ? prev.level2.filter((el) => el._id !== item.value)
                      : [
                          ...prev.level2,
                          { _id: item.value, parentTag: item.parentTag! },
                        ],
                  }))
                }
              >
                {checkIfSelectedTag(item.value, selectedTags.level2) ? (
                  <CheckIcon width={18} height={18} />
                ) : (
                  <NotCheckIcon width={18} height={18} />
                )}
                <span>{item.lable}</span>
              </div>
            ))}
          </div>
        </div>
        <div className={s.tagsBlock}>
          <div
            className={s.headTagBlock}
            style={{ opacity: tags.level3.length ? 1 : 0.5 }}
          >
            <span>{strings.result}</span>
            <div className={s.totalVarinatsBlock}>
              {selectedTags.level3.length + " / " + tags.level3.length}
            </div>
          </div>
          <div className={s.tagsListBlock}>
            {tags.level3.map((item, index) => (
              <div
                key={index}
                className={s.tagElement}
                onClick={() =>
                  setSelectedTags((prev) => ({
                    ...prev,
                    level3: checkIfSelectedTag(item.value, selectedTags.level3)
                      ? prev.level3.filter((el) => el._id !== item.value)
                      : [
                          ...prev.level3,
                          { _id: item.value, parentTag: item.parentTag! },
                        ],
                  }))
                }
              >
                {checkIfSelectedTag(item.value, selectedTags.level3) ? (
                  <CheckIcon width={18} height={18} />
                ) : (
                  <NotCheckIcon width={18} height={18} />
                )}
                <span>{item.lable}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeSpecialistTags;
