import { CircularProgress, ThemeProvider } from "@mui/material";
import { useContext } from "react";
import CalendarIcon from "../../../assets/Header/calendar.svg";
import StandartButton from "../../../components/StandartButton/StandartButton";
import UserAvatarList from "../../../components/UserAvatarList/UserAvatarList";
import { ContextProvider } from "../../../contextProvider";
import strings from "../../../localization";
import { ProjectsAvailableSessionTypes } from "../../../types/Projects";
import { theme } from "../../../utils/theme";
import s from "./MyProgramCard.module.css";

type MyProgramCardProps = {
  projectId: string;
  clientIds: string[];
  programTitle: string;
  programStartDate: string;
  joinProjectHandler: (
    userId: string | undefined,
    projectId: string | undefined
  ) => void;
  joinLoader: boolean;
  isSelected: boolean;
  discount: {
    sessionDiscount: number;
    packageDiscount: number;
  };
  projectType: ProjectsAvailableSessionTypes;
};

const MyProgramCard = ({
  clientIds,
  programTitle,
  programStartDate,
  joinProjectHandler,
  projectId,
  joinLoader,
  isSelected,
  discount,
  projectType,
}: MyProgramCardProps) => {
  const { userData } = useContext(ContextProvider);

  const discountData = {
    [ProjectsAvailableSessionTypes.SESSION as string]: discount.sessionDiscount,
    [ProjectsAvailableSessionTypes.PACKAGE as string]: discount.packageDiscount,
  };

  return (
    <div className={`${s.container} ${isSelected ? s.slectedCard : ""}`}>
      <div className={s.userListBlock}>
        <UserAvatarList
          userIds={clientIds}
          showLimit={4}
          avatarHeight="30px"
          avatarWidth="30px"
          fontSize="14px"
        />
      </div>
      <div className={s.programInfoBlock}>
        <span className={s.programTitle}>{programTitle}</span>
        {discountData[projectType] > 0 && (
          <div className={s.priceBlock}>
            <span>{strings.myProgramDiscountTitle}</span>
            <span>-{discountData[projectType]}% </span>
          </div>
        )}
      </div>
      <div className={s.joinBlock}>
        <div className={s.dateBlock}>
          <img src={CalendarIcon} alt="" />
          <span>{programStartDate}</span>
        </div>
        {!clientIds.includes(userData?._id!) ? (
          joinLoader ? (
            <div className={s.loaderBlock}>
              <ThemeProvider theme={theme}>
                <CircularProgress size={35} color="primary" />
              </ThemeProvider>
            </div>
          ) : (
            <StandartButton
              action={() => joinProjectHandler(userData?._id, projectId)}
              buttonTitle={strings.joinBtn}
              height="35px"
              width="fit-content"
            />
          )
        ) : (
          <span></span>
        )}
      </div>
    </div>
  );
};

export default MyProgramCard;
