import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import AnimateHeight from "react-animate-height";
import UsersApi from "../../../../api/usersApi";
import { ReactComponent as ChevronIcon } from "../../../../assets/HomePage/chevron-up.svg";
import CircleAvatar from "../../../../components/CircleAvatar/CircleAvatar";
import { ContextProvider } from "../../../../contextProvider";
import strings from "../../../../localization";
import s from "./CreateForList.module.css";

type CreateForListProps = {
  selectedCreateFor: string;
  setSelectedCreateFor: Dispatch<SetStateAction<string>>;
  clientsIds: string[];
};
type MemberClient = {
  _id: string;
  name: string;
  surname: string;
  avatar: string;
};
const CreateForList = ({
  selectedCreateFor,
  setSelectedCreateFor,
  clientsIds,
}: CreateForListProps) => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  const [memberClientsData, setMemberClientsData] = useState<
    MemberClient[] | undefined
  >(undefined);
  const [isOpenList, setIsOpenList] = useState(false);

  useEffect(() => {
    (async () => {
      if (!token) return;
      const response = await Promise.all(
        clientsIds.map((item) => UsersApi.getUserPublicAvatarAndName(item))
      );
      const currentMembersData = response.map((item) => ({
        _id: item._id,
        name: item.name,
        surname: item.surname,
        avatar: item.avatar,
      }));
      setMemberClientsData(currentMembersData);
    })();
  }, []);

  return (
    <>
      <span className={s.blockLable}>{strings.createForLable}</span>
      <div className={s.container}>
        {memberClientsData && (
          <div
            className={s.createForSelectedBlock}
            onClick={() => setIsOpenList((prev) => !prev)}
            style={{ padding: isOpenList ? "0 20px 10px 20px" : "0 20px" }}
          >
            <div className={s.selectedUser}>
              <CircleAvatar
                userId={selectedCreateFor}
                width="40px"
                height="40px"
                marginRight="0"
              />
              <span className={s.nameTitle}>
                {userData?._id === selectedCreateFor
                  ? strings.forFourself
                  : memberClientsData.find((el) => el._id === selectedCreateFor)
                      ?.name +
                    " " +
                    memberClientsData.find((el) => el._id === selectedCreateFor)
                      ?.surname}
              </span>
            </div>
            <div style={{ height: "20px" }}>
              <ChevronIcon
                width={20}
                height={20}
                className={isOpenList ? s.chevronUp : s.chevronDown}
              />
            </div>
          </div>
        )}
        <AnimateHeight height={isOpenList ? "auto" : 0}>
          <div
            className={s.membersList}
            style={{
              padding: isOpenList ? "10px 0" : "0",
              borderTop: isOpenList ? "1px solid rgba(0, 0, 0, 0.3)" : "none",
            }}
          >
            <div
              className={
                userData?._id === selectedCreateFor
                  ? s.selectedElemnt
                  : s.notSelectedElement
              }
              onClick={() => setSelectedCreateFor(userData!._id)}
            >
              <CircleAvatar
                userId={userData?._id}
                width="40px"
                height="40px"
                marginRight="0"
              />
              <span className={s.nameTitle}>{strings.forFourself}</span>
            </div>
            {memberClientsData &&
              memberClientsData.map((item, index) => (
                <div
                  className={
                    item._id === selectedCreateFor
                      ? s.selectedElemnt
                      : s.notSelectedElement
                  }
                  key={index}
                  onClick={() => setSelectedCreateFor(item._id)}
                >
                  <img src={item.avatar} alt="" className={s.avatar} />
                  <span className={s.nameTitle}>
                    {item.name + " " + item.surname}
                  </span>
                </div>
              ))}
          </div>
        </AnimateHeight>
      </div>
    </>
  );
};

export default CreateForList;
