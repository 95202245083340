import { Dispatch, SetStateAction, useContext, useRef, useState } from "react";
import s from "./PackageSessionCard.module.css";

import AnimateHeight from "react-animate-height";
import { ReactComponent as ChevronDown } from "../../../../assets/SpecTools/chevron-down.svg";
import { ReactComponent as Delete } from "../../../../assets/SpecTools/delete.svg";
import Input from "../../../../components/Input/Input";
import MultipleSelect from "../../../../components/MultipleSelect/MultipleSelect";
import { ContextProvider } from "../../../../contextProvider";
import useAutosizeTextArea from "../../../../hooks/useAutosizeTextarea";
import strings from "../../../../localization";
import { SpecialistTagsDirections } from "../../../../types/TypeSpecialist";
import { NewPackageType } from "../PackagesTool";

type PackageSessionCardProps = {
  session: {
    title: string;
    description?: string;
    datesAndTime: {
      duration: number;
    }[];
    order: number;
    nextSessionGap: { min: number; max: number };
    specialization: SpecialistTagsDirections | string;
  };

  setSessionState: Dispatch<SetStateAction<NewPackageType>>;
};

const PackageSessionCard = ({
  session,
  setSessionState,
}: PackageSessionCardProps) => {
  const { userData } = useContext(ContextProvider);
  const [cardIsOpen, setCardIsOpen] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useAutosizeTextArea(textAreaRef.current, session.description!);

  const updateSessionField = (order: number, field: string, value: any) => {
    setSessionState((prevPackage) => {
      const updatedSessions = prevPackage.sessions.map((session) => {
        if (session.order === order) {
          if (field.startsWith("datesAndTime.")) {
            const [_, nestedField] = field.split(".");
            return {
              ...session,
              datesAndTime: [
                {
                  ...session.datesAndTime[0],
                  [nestedField]: value,
                },
              ],
            };
          } else {
            return {
              ...session,
              [field]: value,
            };
          }
        }
        return session;
      });

      return { ...prevPackage, sessions: updatedSessions };
    });
  };
  const updateDuration = (order: number, operator: "plus" | "minus") => {
    if (session.datesAndTime[0].duration === (operator === "plus" ? 180 : 10))
      return;
    setSessionState((prevState) => {
      return {
        ...prevState,
        sessions: prevState.sessions.map((session) => {
          if (session.order === order) {
            return {
              ...session,
              datesAndTime: session.datesAndTime.map((dt, index) => {
                if (index === 0) {
                  return {
                    ...dt,
                    duration:
                      operator === "plus" ? dt.duration + 10 : dt.duration - 10,
                  };
                }
                return dt;
              }),
            };
          }
          return session;
        }),
      };
    });
  };
  const deleteSessionByOrder = (order: number) => {
    setSessionState((prevPackage) => {
      const updatedSessions = prevPackage.sessions.filter(
        (session) => session.order !== order
      );
      return { ...prevPackage, sessions: updatedSessions };
    });
  };

  const directions: { [key: string]: SpecialistTagsDirections } = {
    ["Coach"]: SpecialistTagsDirections.COACHING,
    ["Psychologist"]: SpecialistTagsDirections.PSYTHERAPHY,
    ["Mentor"]: SpecialistTagsDirections.MENTORING,
  };

  const sessionRoleData = userData!.specialistIds!.map((item) => ({
    value: directions[item.mainSpecializations[0].specialization],
    lable: item.mainSpecializations[0].labels.find(
      (el) => el.language === userData?.selectedLanguage
    )?.text!,
  }));

  return (
    <div className={s.container}>
      <div className={s.headCardBlock}>
        <div className={s.leftHeadBlock}>
          <ChevronDown
            className={s.chevronIcon}
            onClick={() => setCardIsOpen((prev) => !prev)}
          />
          <input
            type="text"
            value={session.title}
            onChange={(e) =>
              updateSessionField(session.order, "title", e.target.value)
            }
            className={s.titleInput}
            placeholder={strings.packSessionTitlePlaceholder}
          />
        </div>
        <Delete
          onClick={() => deleteSessionByOrder(session.order)}
          className={s.deleteIcon}
        />
      </div>
      <AnimateHeight height={cardIsOpen ? "auto" : 0}>
        {userData &&
          userData.specialistIds &&
          userData.specialistIds.length > 1 && (
            <div className={s.sessionTypeBlock}>
              <span className={s.inputLable}>{strings.sessionType}</span>
              <MultipleSelect
                data={sessionRoleData}
                multiplie={false}
                setValue={(value) => {
                  updateSessionField(session.order, "specialization", value);
                }}
                value={session.specialization}
                bgColor="white"
                border="1px solid #DADADA"
                padding="10px 20px"
              />
            </div>
          )}
        <div className={s.durationBlock}>
          <span className={s.inputLable}>{strings.packSessionDuration}</span>
          <div className={s.durationInput}>
            <span
              className={s.durationBtn}
              onClick={() => updateDuration(session.order, "minus")}
            >
              -
            </span>
            <span>{session.datesAndTime[0].duration}</span>
            <span
              className={s.durationBtn}
              onClick={() => updateDuration(session.order, "plus")}
            >
              +
            </span>
          </div>
          <span className={s.inputLable}>{strings.nextSessionGap}</span>
          <div className={s.gapInputsBlock}>
            <div className={s.gapInput}>
              <Input
                inputValue={session.nextSessionGap.min.toString()}
                onChangeInput={(value) => {
                  const numericValue = value === "" ? 0 : Number(value);
                  if (Number.isInteger(numericValue) && numericValue >= 0) {
                    updateSessionField(session.order, "nextSessionGap", {
                      min: numericValue,
                      max: session.nextSessionGap.max,
                    });
                  }
                }}
                isVisible
                required
                placeholder="min"
                bgColor="white"
                lable="Min"
                type="number"
              />
            </div>{" "}
            <div className={s.gapInput}>
              <Input
                inputValue={session.nextSessionGap.max.toString()}
                onChangeInput={(value) => {
                  const numericValue = value === "" ? 0 : Number(value);

                  if (Number.isInteger(numericValue) && numericValue >= 0) {
                    updateSessionField(session.order, "nextSessionGap", {
                      min: session.nextSessionGap.min,
                      max: numericValue,
                    });
                  }
                }}
                isVisible
                required
                placeholder="max"
                bgColor="white"
                lable="Max"
                type="number"
              />
            </div>
          </div>
          {/* <input
            type="number"
            value={session.datesAndTime[0].duration}
            className={s.durationInput}
            onChange={(e) =>
              updateSessionField(
                session.order,
                "datesAndTime.duration",
                e.target.value
              )
            }
          /> */}
        </div>
        <div className={s.descriptionBlock}>
          <span className={s.inputLable}>{strings.packSessionDescription}</span>
          <textarea
            rows={2}
            name=""
            id=""
            ref={textAreaRef}
            value={session.description}
            onChange={(e) =>
              updateSessionField(session.order, "description", e.target.value)
            }
            className={s.descriptionTextArea}
          ></textarea>
        </div>
      </AnimateHeight>
    </div>
  );
};

export default PackageSessionCard;
