import { ThemeProvider } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { ReactComponent as PlusIcon } from "../../assets/Profile/specialist/add.svg";
import { ReactComponent as CloseIcon } from "../../assets/Profile/specialist/close.svg";
import { ReactComponent as CopyIcon } from "../../assets/Profile/specialist/copy.svg";
import { ContextProvider } from "../../contextProvider";
import strings from "../../localization";
import WorkTimeInput from "../../pages/SpecialistSetupInfo/Price/WorkTimeInput/WorkTimeInput";
import { DaySchedule } from "../../utils/shedule";
import { theme } from "../../utils/theme";
import CopyScheduleMenu from "./CopyScheduleMenu/CopyScheduleMenu";
import s from "./NewShedule.module.css";

type NewSheduleProps = {
  allDaysState: DaySchedule[];
  setDaysState: Dispatch<SetStateAction<DaySchedule[]>>;
};

const NewShedule = ({ setDaysState, allDaysState }: NewSheduleProps) => {
  const { userData } = useContext(ContextProvider);
  const [copyTimeMenuIsOpen, setCopyTimeMenuIsOpen] = useState<
    number | boolean
  >(false);

  function getTimezoneOffset(timezone: string): string {
    const offsetMinutes = moment.tz(timezone).utcOffset();
    const result =
      offsetMinutes > 0 ? `+${offsetMinutes / 60}` : `${offsetMinutes / 60}`;
    return result; // конвертируем минуты в часы
  }

  const handleCancelIconClick = (timeIndex: number, dayValue: string) => {
    const dayIndex = allDaysState.findIndex((el) => el.dayValue === dayValue);

    setDaysState((prev) => {
      const updatedState = [...prev];
      const day = { ...updatedState[dayIndex] };
      day.workTime = day.workTime.filter((_, i) => i !== timeIndex);
      updatedState[dayIndex] = day;

      return updatedState;
    });
  };

  const addGapHandler = (dayValue: string) => {
    const dayIndex = allDaysState.findIndex((el) => el.dayValue === dayValue);

    setDaysState((prev) => {
      const updatedState = [...prev];
      updatedState[dayIndex].workTime = [
        ...updatedState[dayIndex].workTime,
        { from: "", to: "" }, // Изменения здесь
      ];
      return updatedState;
    });
  };

  return (
    <div className={s.container}>
      <div className={s.timeZoneBlock}>
        <span>{strings.timeZoneShedule}</span>
        <span>
          {`(UTC ${getTimezoneOffset(userData?.timezone!)}:00) ` +
            userData?.timezone!}
        </span>
      </div>
      {allDaysState.map((item, index) => (
        <div className={s.dayBlock} key={index}>
          <div className={s.dayTitleBlock}>
            <ThemeProvider theme={theme}>
              <Checkbox
                checked={item.switch}
                onClick={() => {
                  const updatedSchedule = allDaysState.map((day) =>
                    day.dayValue === item.dayValue
                      ? {
                          ...day,
                          switch: !day.switch,
                          workTime: day.switch ? [] : [{ from: "", to: "" }],
                        }
                      : day
                  );
                  setDaysState(updatedSchedule);
                }}
                inputProps={{ "aria-label": "controlled" }}
                color={"primary"}
              />{" "}
            </ThemeProvider>
            {item.daytitle}{" "}
          </div>
          {item.switch ? (
            <div className={s.daySettingsBlock}>
              <div className={s.daySlotsListBlock}>
                {item.workTime.map((_, indexInterval) => (
                  <div className={s.timeElement} key={indexInterval}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <WorkTimeInput
                        inputsValue={allDaysState}
                        mainIndex={allDaysState.findIndex(
                          (el) => el.dayValue === item.dayValue
                        )}
                        setInputsValue={setDaysState}
                        timeIndex={indexInterval}
                        height="40px"
                        isForProfile
                      />

                      <div
                        role="button"
                        onClick={() => {
                          {
                            const updatedSchedule = allDaysState.map((day) =>
                              day.dayValue === item.dayValue
                                ? {
                                    ...day,
                                    switch: !day.switch,
                                  }
                                : day
                            );
                            item.workTime.length === 1 &&
                              setDaysState(updatedSchedule);
                            handleCancelIconClick(indexInterval, item.dayValue);
                          }
                        }}
                        className={s.iconButton}
                      >
                        <CloseIcon width={20} height={20} />
                      </div>
                    </div>
                    {indexInterval === 0 && (
                      <div className={s.actionBlock}>
                        <div
                          role="button"
                          onClick={() => addGapHandler(item.dayValue)}
                          className={s.iconButton}
                        >
                          <PlusIcon />
                        </div>
                        <div
                          role="button"
                          onClick={() => setCopyTimeMenuIsOpen(index)}
                          className={s.iconButton}
                        >
                          <CopyIcon width={20} height={20} />
                        </div>
                        {copyTimeMenuIsOpen === index && (
                          <CopyScheduleMenu
                            allDaysState={allDaysState}
                            selectedDay={item.dayValue}
                            setDaysState={setDaysState}
                            setCopyMenuIsOpen={setCopyTimeMenuIsOpen}
                          />
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <span className={s.dayOffTitle}>{strings.dayOff}</span>
          )}
        </div>
      ))}
    </div>
  );
};

export default NewShedule;
