import { Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import SessionsApi from "../../../../api/sessionApi";
import { ReactComponent as StatisticIcon } from "../../../../assets/Packages/statistic.svg";
import { ReactComponent as PlusIcon } from "../../../../assets/Profile/specialist/add.svg";
import Loader from "../../../../components/Loader/Loader";
import strings from "../../../../localization";
import { SessionPackage } from "../../../../types/TypeSession";
import { SpecialistData } from "../../../../types/TypeSpecialist";
import { TypeUserData } from "../../../../types/TypeUsers";
import Notify from "../../../../utils/toaster";
import PackageCard from "./PackageCard/PackageCard";
import s from "./Packages.module.css";

type PackagesProps = {
  profileUserData: TypeUserData | null | undefined;
  specialistData: SpecialistData | undefined;
};

type PackagesStatistics = {
  totalBookedPackages: number;
  totalEarned: number;
  totalPackages: number;
};

const Packages = ({ profileUserData, specialistData }: PackagesProps) => {
  const token = localStorage.getItem("token");
  const [packagesData, setPackagesData] = useState<{
    packagesData: SessionPackage[];
    overlaps: {
      day: string;
      overlapTime: {
        start: Date;
        end: Date;
      }[];
    }[];
  } | null>(null);
  const [actionLoaders, setActionLoaders] = useState({ mainLoader: true });
  const [packagesStatistics, setPackagesStatistics] = useState<
    PackagesStatistics | undefined
  >();

  useEffect(() => {
    const makeAsync = async () => {
      if (!token || !profileUserData || !specialistData) {
        return setActionLoaders((prev) => ({ ...prev, mainLoader: false }));
      }
      setActionLoaders((prev) => ({ ...prev, mainLoader: true }));
      const response = await SessionsApi.getSpecialistSessionPackages(
        token,
        profileUserData._id
      );
      const statisticResponse = await SessionsApi.getGeneralPackagesInfo(
        token,
        profileUserData._id
      );
      if (statisticResponse.status) {
        setPackagesStatistics({
          totalBookedPackages: statisticResponse.totalBookedPackages,
          totalEarned: statisticResponse.totalEarned,
          totalPackages: statisticResponse.totalPackages,
        });
      }

      if (response.status && response.packages) {
        setPackagesData({
          packagesData: response.packages,
          overlaps: [],
        });
      }
      setActionLoaders((prev) => ({ ...prev, mainLoader: false }));
      if (!response.status && response.message) {
        return Notify(response.message);
      }
    };
    makeAsync();
  }, [profileUserData]);

  if (actionLoaders.mainLoader) {
    return (
      <div className={s.laoderContainer}>
        <Loader size={100} />
      </div>
    );
  }

  return (
    <div className={s.container}>
      <div className={s.packagesHeader}>
        <a href="/tools/package">
          <div className={s.createPackageBtn}>
            <PlusIcon className={s.plusIcon} />
            <span>{strings.createPackageBtn}</span>
          </div>
        </a>
      </div>
      {packagesStatistics ? (
        <div className={s.statisticsSection}>
          <div className={s.statisticHeader}>
            <StatisticIcon />
            <div className={s.statisticTitle}>
              <span>{strings.statisticsTitle}</span>
              <span> {strings.allTime}</span>
            </div>
          </div>
          <div className={s.statisticElementsBlock}>
            <div className={s.statisticElement}>
              <span>{packagesStatistics.totalPackages}</span>
              <span>{strings.publishePackageStatistic}</span>
            </div>
            <div className={s.statisticElement}>
              <span>{packagesStatistics.totalBookedPackages}</span>
              <span>{strings.soldPackageStatistic}</span>
            </div>
            <div className={s.statisticElement}>
              <span>{packagesStatistics.totalEarned / 100}</span>
              <span>{strings.earningspackageStatistic}</span>
            </div>
          </div>
        </div>
      ) : (
        <Skeleton />
      )}
      {!actionLoaders.mainLoader &&
      (!packagesData || !packagesData.packagesData.length) ? (
        <div className={s.laoderContainer}>
          <h2>{strings.noPackages}</h2>
        </div>
      ) : null}

      {packagesData && packagesData.packagesData.length
        ? packagesData.packagesData.map((item, index) => (
            <PackageCard
              profileUserData={profileUserData}
              sessionPackage={item}
              overlaps={packagesData.overlaps}
              setPackagesData={setPackagesData}
              specialistData={specialistData}
              key={index}
            />
          ))
        : null}
    </div>
  );
};

export default Packages;
