import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import MessageChatApi from "../../../api/messageChatApi";
import UsersApi from "../../../api/usersApi";
import { ReactComponent as ScrapIcon } from "../../../assets/Notes/scrap.svg";
import ArrowLeftIcon from "../../../assets/VideoSession/arrow-Left.svg";
import { ReactComponent as CloseIcon } from "../../../assets/VideoSession/close.svg";
import CircleAvatar from "../../../components/CircleAvatar/CircleAvatar";
import { network } from "../../../config";
import { ContextProvider } from "../../../contextProvider";
import strings from "../../../localization";
import TypeDefaultResponse from "../../../types/TypeDefaultResponse";
import { TypeMessage } from "../../../types/TypeMessageChat";
import { formatedFiles } from "../../../utils/messageChat";
import socket from "../../../utils/socket";
import useAutosizeTextArea from "../../../utils/useAutosizeTextArea";
import s from "./Chat.module.css";
import FilePreview from "./FilePreview";
import MessageComponent from "./MessageComponent/MessageComponent";

type TypeChatprops = {
  sessionId: string;
  chatHistory: TypeMessage[];
  avatars: { id: string; avatar: string }[] | undefined;
  setToolsState: Dispatch<
    SetStateAction<{
      chat: boolean;
      tasks: boolean;
      notations: boolean;
    }>
  >;
};

interface Message {
  messageId: string;
  userId: string;
  text: string;
  sender: string;
  files: any[];
}
const { chat } = network;
const Chat = ({
  chatHistory,
  avatars,
  setToolsState,
  sessionId,
}: TypeChatprops) => {
  const { userData } = useContext(ContextProvider);
  const token = localStorage.getItem("token");
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState("");
  const chatBodyRef = useRef<HTMLDivElement>(null);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [nameSurname, setNameSurname] = useState({ name: "", surname: "" });

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useAutosizeTextArea(textAreaRef.current, newMessage);
  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    const val = evt.target?.value;
    setNewMessage(val);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const newFiles = Array.from(files).filter(
        (file) => !file.type.includes("video")
      );

      if (newFiles.length + selectedFiles.length > 5) {
        alert("You can only select up to 5 files at a time.");
        e.target.value = ""; // Reset the input to allow re-selecting files
        return;
      }

      setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const handleSendMessage = async () => {
    if (
      userData &&
      userData._id &&
      (newMessage !== "" || selectedFiles.length > 0)
    ) {
      const messageText = newMessage;
      const files = selectedFiles;
      setNewMessage("");
      setSelectedFiles([]);
      const response = await MessageChatApi.sendMessage({
        sessionId: sessionId,
        message: messageText,
        userId: userData._id,
        date: new Date(),
        files: Array.from(files).map((file: any) => {
          return { name: file.name, buffer: file, size: file.size };
        }),
      });

      if (response.status && userData) {
        const files = response.newMessage?.files.length
          ? formatedFiles(response.newMessage?.files)
          : [];

        setMessages((prev) => [
          ...prev,
          {
            messageId: response.newMessage?._id ? response.newMessage?._id : "",
            userId: userData._id,
            text: response.newMessage?.message ?? "",
            sender: "user",
            files: files ? files : [],
          },
        ]);
      }
    }
  };
  useEffect(() => {
    const newFormatChatHistory = chatHistory.map((item) => ({
      messageId: item._id,
      userId: item.user && item.user._id ? item.user._id : "",
      text: item.message,
      sender: userData?._id === item.user?._id ? "user" : "other",
      files: item.files ? item.files : [],
    }));

    setMessages(newFormatChatHistory);
  }, [chatHistory]);

  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [messages]);
  useEffect(() => {
    socket.on(
      chat.newMessage,
      (
        response: TypeDefaultResponse & {
          newMessage?: TypeMessage | null;
        }
      ) => {
        setMessages((prev) => {
          if (
            response.newMessage &&
            response.newMessage.user &&
            response.newMessage.user._id
          )
            return [
              ...prev,
              {
                messageId: response.newMessage._id,
                userId: response.newMessage.user._id,
                text: response.newMessage.message,
                sender: "other",
                files: response.newMessage.files,
              },
            ];
          return prev;
        });
      }
    );
  }, []);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  useEffect(() => {
    const makeAsync = async () => {
      if (token && avatars && avatars[0]) {
        const response = await UsersApi.getUserPublicAvatarAndName(
          avatars[0].id
        );

        if (response.name && response.surname) {
          setNameSurname({
            name: response.name,
            surname: response.surname,
          });
        }
      }
    };
    makeAsync();
  }, [avatars]);

  return (
    <div className={s.container}>
      <div className={s.chatHeader}>
        <h3 className={s.chatTitle}>{strings.chatTitle}</h3>
        <CloseIcon
          className={s.closeIcon}
          onClick={() =>
            setToolsState((prev) => ({
              chat: !prev.chat,
              tasks: false,
              notations: false,
            }))
          }
        />
      </div>
      <div className={s.chatMobileHeader}>
        <div className={s.companionInfo}>
          <CircleAvatar userId={avatars && avatars[0] ? avatars[0].id : ""} />
          <span>{nameSurname.name + " " + nameSurname.surname}</span>
        </div>
        <CloseIcon
          className={s.closeIcon}
          onClick={() =>
            setToolsState((prev) => ({
              chat: !prev.chat,
              tasks: false,
              notations: false,
            }))
          }
        />
      </div>
      <div className={s.chatBody} ref={chatBodyRef}>
        {messages.map((message, index) => (
          <MessageComponent message={message} key={index} />
        ))}
      </div>
      <div className={s.filePreviewBlock}>
        {selectedFiles.map((file, index) => (
          <FilePreview
            key={index}
            file={file}
            onDelete={() =>
              setSelectedFiles((prevFiles) =>
                prevFiles.filter((_, i) => i !== index)
              )
            }
          />
        ))}
      </div>
      <div className={s.chatInputBlock}>
        <textarea
          id="review-text"
          ref={textAreaRef}
          value={newMessage}
          placeholder={strings.typeMessagePlaceholder}
          className={s.chatInput}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          rows={1}
          style={{
            resize: "none",
            overflowY: "auto",
            maxHeight: "300px",
          }}
        />

        <input
          type="file"
          id="fileInput"
          accept="image/*, application/pdf, .txt, .doc, .docx, .xml, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />

        <div className={s.actionChatBlock}>
          <ScrapIcon
            className={s.scrapIcon}
            onClick={() => document.getElementById("fileInput")?.click()}
          />
          <button onClick={handleSendMessage} className={s.sendButton}>
            <img src={ArrowLeftIcon} alt="" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Chat;
