import { Skeleton, Tooltip } from "@mui/material";
import { GlobalWorkerOptions } from "pdfjs-dist";
import { useContext, useEffect, useState } from "react";
import { ContextProvider } from "../../../../../contextProvider";
import strings from "../../../../../localization";
import { EducationCertificatesResponse } from "../../../../../types/TypeSpecialist";
import { formatEducationPeriod } from "../../../../../utils/dateActions";
import { getPdfThumbnail } from "../../../../../utils/filesAction";
import s from "../EducationSection.module.css";

GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js`;

type EducationCardProps = {
  educationData: EducationCertificatesResponse;
  previewFileHandler: (link: string, type: string) => void;
};

const EducationCard = ({
  educationData,
  previewFileHandler,
}: EducationCardProps) => {
  const { userData } = useContext(ContextProvider);
  const [certificateThumbnail, setCertificateThumbnail] = useState<
    string | undefined
  >();
  const [thumbnailLoader, setThumbnailLoader] = useState(false);
  useEffect(() => {
    const loadThumbnail = async () => {
      if (educationData.type === "pdf") {
        setThumbnailLoader(true);
        const thumbnail = await getPdfThumbnail(educationData.link);
        setCertificateThumbnail(thumbnail);
        setThumbnailLoader(false);
      } else {
        setCertificateThumbnail(educationData.link);
      }
    };

    loadThumbnail();
  }, [educationData]);
  return (
    <div className={s.certificateElement}>
      {certificateThumbnail && !thumbnailLoader ? (
        <Tooltip arrow title={strings.showCertificate}>
          <div
            onClick={() =>
              previewFileHandler(educationData.link, educationData.type)
            }
            className={s.showCertificateBtn}
          >
            <img
              src={certificateThumbnail}
              alt="PDF Thumbnail"
              className={s.thumbnailCertificate}
            />
          </div>
        </Tooltip>
      ) : (
        <Skeleton variant="rounded" width={"100%"} height={200} />
      )}
      <div className={s.mainInfoCertificateBlock}>
        <div className={s.headCertificateBlock}>
          <div>
            <span>{educationData.education.text}</span>
            <span className={s.educationDescription}>
              {educationData.description}
            </span>
          </div>
        </div>
        <div className={s.bottomCertificateBlock}>
          <span>
            {formatEducationPeriod(
              new Date(educationData.membershipStart),
              new Date(educationData.membershipEnd),
              userData?.selectedLanguage!
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default EducationCard;
