import dayjs, { Dayjs } from "dayjs";
import { Dispatch, SetStateAction, useState } from "react";
import SpecialistApi from "../../../api/specialistApi";
import EditIcon from "../../../assets/Profile/cloud-plus.svg";
import { ReactComponent as PlusIcon } from "../../../assets/Profile/specialist/add.svg";
import strings from "../../../localization";
import {
  Education,
  EducationCertificatesResponse,
  Federation,
  FederationsCertificatesResponse,
  Qualification,
  QualificationCertificatesResponse,
} from "../../../types/TypeSpecialist";
import { compressFiles } from "../../../utils/compressionFile";
import Notify, { SuccesNotify } from "../../../utils/toaster";
import Input from "../../Input/Input";
import MuiDatePicker from "../../MuiDatePicker/MuiDatePicker";
import MultipleSelect from "../../MultipleSelect/MultipleSelect";
import StandartButton from "../../StandartButton/StandartButton";
import s from "../AddCertificatesModal.module.css";

type CertificatesData = {
  education: EducationCertificatesResponse[] | undefined;
  qualification: QualificationCertificatesResponse[] | undefined;
  federation: FederationsCertificatesResponse[] | undefined;
};

type FederationsCertificates = {
  selectData: {
    education?: Education[];
    qualification?: Qualification[];
    federation?: Federation[];
  };
  setActionLoaders: Dispatch<SetStateAction<{ addedCertificate: boolean }>>;
  specialistId: string;
  specialization: string;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  setNewCertificate: Dispatch<SetStateAction<CertificatesData>>;
};

type NewFederation = {
  newFederation: {
    federationText: string | undefined;
    badge: {
      file: File | undefined;
      url: string | undefined;
      federationId: string | undefined;
    };
  };
  federationToSave: {
    file: File | undefined;
    federationId: string | undefined;
    membershipStart: Date | undefined;
    membershipEnd: Date | undefined;
    federationProfileUrl: string | undefined;
  };
};

const initialFederation: NewFederation = {
  newFederation: {
    federationText: undefined,
    badge: {
      file: undefined,
      url: undefined,
      federationId: undefined,
    },
  },
  federationToSave: {
    file: undefined,
    federationId: undefined,
    membershipStart: undefined,
    membershipEnd: undefined,
    federationProfileUrl: undefined,
  },
};

const FederationsCertificates = ({
  selectData,
  setActionLoaders,
  specialistId,
  specialization,
  setModalOpen,
  setNewCertificate,
}: FederationsCertificates) => {
  const token = localStorage.getItem("token");
  const [selectValue, setSelectValue] = useState<string>("");
  const [addFederation, setAddFederation] =
    useState<NewFederation>(initialFederation);

  const handleCertificateChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target && event.target.files) {
      const file = event.target.files[0];
      if (file && file.type.includes("pdf")) {
        setAddFederation((prev) => ({
          ...prev,
          federationToSave: {
            ...prev.federationToSave,
            file: file,
          },
        }));
      }
      if (file && !file.type.includes("pdf")) {
        const compressedFile = await compressFiles(file);
        if (compressedFile.file) {
          setAddFederation((prev) => ({
            ...prev,
            federationToSave: {
              ...prev.federationToSave,
              file: compressedFile.file,
            },
          }));
        }
      }
    }
  };

  const handleBadgeIconChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target && event.target.files) {
      const file = event.target.files[0];

      if (file) {
        const compressedFile = await compressFiles(file);
        if (compressedFile.file) {
          setAddFederation((prev) => ({
            ...prev,
            newFederation: {
              ...prev.newFederation,
              badge: { ...prev.newFederation.badge, file: compressedFile.file },
            },
          }));
        }
      }
    }
  };
  const createFederation = async (bacdgeId: string) => {
    if (!token) return;
    const payload = {
      federationText: addFederation.newFederation.federationText!,
      specialization: specialization,
      badgeId: bacdgeId,
    };
    const response = await SpecialistApi.createFederation(token, payload);
    return { status: response.status, federationId: response.federation?._id };
  };
  const createFederationBadge = async () => {
    if (!token) return;
    const formData = new FormData();
    formData.append("file", addFederation.newFederation.badge.file!);
    formData.append("url", addFederation.newFederation.badge.url!);
    const response = await SpecialistApi.createFederationBadge(token, formData);
    return { status: response.status, badgeId: response.badge?._id };
  };
  const saveFederation = async () => {
    if (!token) return;
    setActionLoaders((prev) => ({ ...prev, addedCertificate: true }));
    let badgeId: string | undefined = undefined;
    let federationId: string | undefined = undefined;
    if (selectValue === "other") {
      const badgeResponse = await createFederationBadge();
      badgeId = badgeResponse?.badgeId;
    }
    if (badgeId) {
      const createFederationResponse = await createFederation(badgeId);
      federationId = createFederationResponse?.federationId;
    }
    if (selectValue && selectValue !== "other") {
      federationId = selectValue;
    }
    if (federationId) {
      const formData = new FormData();
      formData.append("file", addFederation.federationToSave.file!);
      formData.append("federationId", federationId);
      formData.append(
        "membershipStart",
        addFederation.federationToSave.membershipStart!.toISOString()
      );
      formData.append(
        "membershipEnd",
        addFederation.federationToSave.membershipEnd!.toISOString()
      );
      formData.append(
        "federationProfileUrl",
        addFederation.federationToSave.federationProfileUrl!
      );
      const mainResponse = await SpecialistApi.uploadFederationCertificates(
        specialistId,
        formData,
        token
      );

      if (mainResponse.status) {
        SuccesNotify(strings.federationSucces);
        setAddFederation(initialFederation);
        setModalOpen(false);
        setSelectValue("");
        setActionLoaders((prev) => ({ ...prev, addedCertificate: false }));
        setNewCertificate((prev) => ({
          ...prev,
          federation: prev.federation
            ? [...prev.federation, mainResponse.certificate]
            : [mainResponse.certificate],
        }));
      }
      if (!mainResponse.status && mainResponse.message) {
        Notify(mainResponse.message);
        setActionLoaders((prev) => ({ ...prev, addedCertificate: false }));
      }
    }
  };

  const validateURL = (url: string | undefined) => {
    if (!url) return false;
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  const validationNewCertificate = () => {
    const federationToSave = addFederation.federationToSave;
    const newFederation = addFederation.newFederation;

    if (!selectValue) return false;
    if (
      !validateURL(federationToSave.federationProfileUrl) ||
      !federationToSave.membershipStart ||
      !federationToSave.membershipEnd ||
      !federationToSave.file
    )
      return false;
    if (
      selectValue === "other" &&
      (!newFederation.federationText ||
        !newFederation.badge.file ||
        !validateURL(newFederation.badge.url))
    )
      return false;
    return true;
  };

  return (
    <div className={`${s.container} ${s.federationContainer}`}>
      {selectData &&
        selectData.federation &&
        (selectValue !== "other" ? (
          <MultipleSelect
            data={[
              ...(selectData.federation.map((item) => ({
                lable: item.text,
                value: item._id,
              })) ?? []),
              { lable: strings.otherCertificate, value: "other" },
            ]}
            multiplie={false}
            setValue={(value) => setSelectValue(value as string)}
            value={selectValue}
            padding="10px"
            lable={strings.federationTitle}
          />
        ) : (
          <div className={s.newFederationBlock}>
            <Input
              inputValue={addFederation.newFederation.federationText ?? ""}
              isVisible
              required
              onChangeInput={(value) =>
                setAddFederation((prev) => ({
                  ...prev,
                  newFederation: {
                    ...prev.newFederation,
                    federationText: value,
                  },
                }))
              }
              lable={strings.federationTitle}
            />
            <Input
              inputValue={addFederation.newFederation.badge.url ?? ""}
              isVisible
              required
              onChangeInput={(value) =>
                setAddFederation((prev) => ({
                  ...prev,
                  newFederation: {
                    ...prev.newFederation,
                    badge: { ...prev.newFederation.badge, url: value },
                  },
                }))
              }
              lable={strings.badgeUrl}
              border={
                validateURL(addFederation.newFederation.badge.url) ||
                !addFederation.newFederation.badge.url ||
                !addFederation.newFederation.badge.url.length
                  ? "none"
                  : "1px solid red"
              }
              placeholder="https://www.credly.com/org/international-coach-federation/badge/icf-member-badge"
            />
            <div>
              <span className={s.secondLable}>{strings.badgeIcon}</span>
              <div className={s.uploadFilesActionBlock}>
                {addFederation.newFederation.badge.file && (
                  <img
                    src={URL.createObjectURL(
                      addFederation.newFederation.badge.file
                    )}
                    alt=""
                    className={s.badgeIcon}
                  />
                )}
                <label className={s.fileInputBlock}>
                  <input
                    style={{ display: "none" }}
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={handleBadgeIconChange}
                  />
                  {addFederation.newFederation.badge.file ? (
                    <span className={s.updateAvatarBtn} role="button">
                      <img src={EditIcon} alt="" />
                      <span>{strings.replace}</span>
                    </span>
                  ) : (
                    <div className={s.addCertificateBtn}>
                      <PlusIcon className={s.plusIcon} />
                      <span>{strings.addBadge}</span>
                    </div>
                  )}
                </label>
              </div>
            </div>
          </div>
        ))}
      <div className={s.inputBlock}>
        <Input
          inputValue={addFederation.federationToSave.federationProfileUrl ?? ""}
          isVisible
          required
          onChangeInput={(value) =>
            setAddFederation((prev) => ({
              ...prev,
              federationToSave: {
                ...prev.federationToSave,
                federationProfileUrl: value,
              },
            }))
          }
          lable={strings.federationProfileUrl}
          placeholder="https://apps.coachingfederation.org/eweb/CCFDynamicPage.aspx?webcode=ccfcoachprofileview&coachcstkey=A26D427B-86A3-4EE3-873E-D1468F4C48FB"
          border={
            validateURL(addFederation.federationToSave.federationProfileUrl) ||
            !addFederation.federationToSave.federationProfileUrl ||
            !addFederation.federationToSave.federationProfileUrl.length
              ? "none"
              : "1px solid red"
          }
        />
      </div>
      <div className={s.dateBlock}>
        <span className={s.secondLable}>
          {strings.federationDatesDescription}
        </span>
        <div className={s.dateInputBlock}>
          <div className={s.dateInput}>
            <MuiDatePicker
              value={
                addFederation.federationToSave.membershipStart &&
                dayjs(addFederation.federationToSave.membershipStart)
              }
              setValue={(value: Dayjs | null) =>
                setAddFederation((prev) => ({
                  ...prev,
                  federationToSave: {
                    ...prev.federationToSave,
                    membershipStart: value?.toDate(),
                  },
                }))
              }
            />
          </div>
          <div className={s.dateInput}>
            <MuiDatePicker
              value={
                addFederation.federationToSave.membershipEnd &&
                dayjs(addFederation.federationToSave.membershipEnd)
              }
              setValue={(value: Dayjs | null) =>
                setAddFederation((prev) => ({
                  ...prev,
                  federationToSave: {
                    ...prev.federationToSave,
                    membershipEnd: value?.toDate(),
                  },
                }))
              }
            />
          </div>
        </div>
      </div>
      <div className={s.uploadCertificateBlock}>
        <span className={s.headLable}>{strings.certificatesTitle}</span>
        <span className={s.secondLable}>{strings.certificateDescr}</span>
        <div className={s.uploadFilesActionBlock}>
          {addFederation.federationToSave.file && (
            <div>{addFederation.federationToSave.file.name}</div>
          )}
          <label className={s.fileInputBlock}>
            <input
              style={{ display: "none" }}
              type="file"
              accept=".pdf, image/*"
              multiple
              onChange={handleCertificateChange}
            />
            {addFederation.federationToSave.file ? (
              <span className={s.updateAvatarBtn} role="button">
                <img src={EditIcon} alt="" />
                <span>{strings.replace}</span>
              </span>
            ) : (
              <div className={s.addCertificateBtn}>
                <PlusIcon />
                <span>{strings.addCertificate}</span>
              </div>
            )}
          </label>
        </div>
      </div>
      <StandartButton
        action={saveFederation}
        buttonTitle={strings.saveCertificateBtn}
        width="100%"
        disabled={!validationNewCertificate()}
      />
    </div>
  );
};

export default FederationsCertificates;
