import { useContext, useState } from "react";
import UsersApi from "../../api/usersApi";
import GroupBg2 from "../../assets/Auth/Group-1.png";
import GroupBg3 from "../../assets/Auth/Group-2.png";
import GroupBg1 from "../../assets/Auth/Group.png";
import LockIng from "../../assets/Auth/lock.png";
import { ReactComponent as LogOutIcon } from "../../assets/SideBar/logout.svg";
import Loader from "../../components/Loader/Loader";
import PhoneNumberInput from "../../components/PhoneNumberInput/PhoneNumberInput";
import StandartButton from "../../components/StandartButton/StandartButton";
import { ContextProvider } from "../../contextProvider";
import strings from "../../localization";
import { logout } from "../../utils/general";
import s from "./ConnectPhoneNumber.module.css";

const ConnectPhoneNumber = () => {
  const token = localStorage.getItem("token");
  const { setSelectLang, selectLang, setUserData } =
    useContext(ContextProvider);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState<string | undefined>();
  const [loader, setLoader] = useState(false);

  const changeLangHandler = async (value: string) => {
    strings.setLanguage(value);
    setSelectLang(value);
  };

  const connectPhoneNumber = async () => {
    if (!token) return;
    setLoader(true);
    const response = await UsersApi.connectPhoneNumber(
      token,
      phoneNumber,
      selectLang ?? "en"
    );
    setLoader(false);
    if (!response.status && response.message) {
      setError(response.message);
    }
    if (response.status) {
      setUserData(response.userData);
    }
  };

  return (
    <div className={s.container} data-testid="confirm-reg">
      <div className={s.langSelectBlock}>
        <button
          onClick={() => changeLangHandler("en")}
          className={selectLang === "en" ? s.selectLang : s.unselectLang}
        >
          EN
        </button>
        <button
          onClick={() => changeLangHandler("uk")}
          className={selectLang === "uk" ? s.selectLang : s.unselectLang}
        >
          УКР
        </button>
        <LogOutIcon className={s.loguotIcon} onClick={logout} />
      </div>
      <div className={s.mainContentBlock}>
        <img src={LockIng} alt="" className={s.logo} />
        <h1 className={s.headTitle}>{strings.connectPhoneNumber}</h1>
        <p className={s.codeDescription}>{strings.connectPhoneDescription1}</p>
        <p className={s.codeDescription}>{strings.connectPhoneDescription2}</p>
        <ol className={s.codeDescriptionList}>
          <li>{strings.connectPhoneStep1}</li>
          <li>{strings.connectPhoneStep2}</li>
          <li>{strings.connectPhoneStep3}</li>
        </ol>
        <div className={s.inputBlock}>
          <PhoneNumberInput
            onChangeFunction={(value) => setPhoneNumber(value)}
            value={phoneNumber}
          />
        </div>
        <div className={s.inputBlock}>
          {loader ? (
            <Loader size={42} />
          ) : (
            <StandartButton
              action={connectPhoneNumber}
              buttonTitle="OK"
              width="100%"
            />
          )}
        </div>
        <div className={s.errorMessage}>{error}</div>
      </div>

      <div className={s.footerBlock}></div>

      <img src={GroupBg1} alt="" className={s.group1} />
      <img src={GroupBg2} alt="" className={s.group2} />
      <img src={GroupBg3} alt="" className={s.group3} />
    </div>
  );
};

export default ConnectPhoneNumber;
