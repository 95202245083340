import { Tooltip } from "@mui/material";
import countries from "i18n-iso-countries";
import { Dispatch, SetStateAction, useContext } from "react";
import NotificationsApi from "../../../api/notifications";
import CircleAvatar from "../../../components/CircleAvatar/CircleAvatar";
import StandartButton from "../../../components/StandartButton/StandartButton";
import { ContextProvider } from "../../../contextProvider";
import strings from "../../../localization";
import { TypeUpdatedUserInfo, TypeUserData } from "../../../types/TypeUsers";
import { formatDate } from "../../../utils/setupProfileInfo";
import PublishedPosts from "../SpecialistProfileContent/PublishedPosts/PublishedPosts";
import SubscribeBtn from "../SubscribeBtn/SubscribeBtn";
import s from "./ClientProfileContent.module.css";
import EditClientProfile from "./EditProfile/EditClientProfile";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

type ClientProfileContent = {
  isEdit: boolean;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  updateData: () => void;
  userUpdateData: TypeUpdatedUserInfo;
  setUserUpdateData: Dispatch<SetStateAction<TypeUpdatedUserInfo>>;
  profileUserData: TypeUserData | undefined | null;
  calendarIsConnected: boolean;
  setAvatarFile: Dispatch<SetStateAction<File | null>>;
  avatarFile: File | null;
};

const ClientProfileContent = ({
  isEdit,
  userUpdateData,
  setUserUpdateData,
  profileUserData,
  calendarIsConnected,
  updateData,
  setIsEdit,
  setAvatarFile,
  avatarFile,
}: ClientProfileContent) => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  /*   const [activeTab, setActiveTab] = useState<"general" | "posts">("general"); */
  const activeTab = "general";

  const connectGoogleCalendar = async () => {
    if (!token) return;
    const response = await NotificationsApi.generateCode(token);
    if (response.status && response.url) {
      window.open(response.url);
    }
  };

  return (
    <div className={s.container}>
      {isEdit ? (
        <EditClientProfile
          profileUserData={profileUserData}
          setUserUpdateData={setUserUpdateData}
          userUpdateData={userUpdateData}
          updateData={updateData}
          calendarIsConnected={calendarIsConnected}
          avatarFile={avatarFile}
          setAvatarFile={setAvatarFile}
        />
      ) : (
        <>
          <div className={s.headBlock}>
            <div className={s.headNameBlock}>
              <div className={s.mobileAvatarBlock}>
                <CircleAvatar
                  userId={profileUserData?._id || ""}
                  name={profileUserData?.name || ""}
                  surname={profileUserData?.surname || ""}
                  height="110px"
                  width="110px"
                  fontSize="52px"
                  marginRight="20px"
                  isModalAvatar
                />
              </div>
              <div className={s.nameBlock}>
                <span className={s.nameTitle}>
                  {profileUserData?.name} {profileUserData?.surname}
                </span>
                <span className={s.locationTitle}>
                  {profileUserData?.location}
                </span>
              </div>
            </div>
            <div className={s.bottomHeadBlock}>
              <div className={s.actionBlock}>
                {userData?._id === profileUserData?._id ||
                !profileUserData?._id ? (
                  <div className={s.myProfileActionBtn}>
                    <StandartButton
                      buttonTitle={strings.editProfileBtn}
                      action={() => setIsEdit(true)}
                    />
                  </div>
                ) : (
                  <SubscribeBtn userId={profileUserData?._id!} />
                )}
              </div>
            </div>
          </div>

          {activeTab === "general" ? (
            <div className={s.mainContentBlock}>
              <div className={s.mainInfoBlock}>
                <div className={s.infoTitleBlock}>
                  <span>{strings.profilePersonInfo}</span>
                </div>
                <div className={s.personInfoBlock}>
                  <div className={s.personInfoLineBlock}>
                    <div className={s.personInfoElement}>
                      <span className={s.infoLable}>
                        {strings.firstNameProfileLable}
                      </span>

                      <span className={s.infoValue}>
                        {profileUserData?.name}
                      </span>
                    </div>
                    <div className={s.personInfoElement}>
                      <span className={s.infoLable}>
                        {strings.lastNameProfileLable}
                      </span>

                      <span className={s.infoValue}>
                        {profileUserData?.surname}
                      </span>
                    </div>
                  </div>
                </div>
                <div className={s.personInfoBlock}>
                  <div className={s.personInfoLineBlock}>
                    <div className={s.personInfoElement}>
                      <span className={s.infoLable}>
                        {strings.dateBirthProfileLable}
                      </span>

                      <span className={s.infoValue}>
                        {formatDate(
                          profileUserData?.dateOfBirth
                            ? new Date(profileUserData?.dateOfBirth)
                            : new Date()
                        )}
                      </span>
                    </div>
                    <div className={s.personInfoElement}>
                      <span className={s.infoLable}>
                        {strings.timezoneProfileLable}
                      </span>

                      <span className={s.infoValue}>
                        {profileUserData?.timezone}
                      </span>
                    </div>
                  </div>
                </div>
                <div className={s.personInfoBlock}>
                  <div className={s.personInfoLineBlock}>
                    <div className={s.personInfoElement}>
                      <span className={s.infoLable}>
                        {strings.fromProfileLable}
                      </span>

                      <span className={s.infoValue}>
                        {profileUserData?.location}
                      </span>
                    </div>
                    <div className={s.personInfoElement}>
                      <span className={s.infoLable}>
                        {strings.langProfileLable}
                      </span>

                      <div className={s.langBlock}>
                        {profileUserData &&
                          profileUserData.preferredLanguages &&
                          profileUserData?.preferredLanguages.map(
                            (item, index) => (
                              <span className={s.infoValue} key={index}>
                                {item}
                              </span>
                            )
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={s.integrationsBlock}>
                <div className={s.infoTitleBlock}>
                  <span>{strings.intagrationProfile}</span>
                </div>

                <div className={s.integrationsElement}>
                  <p className={s.infoLable}>{strings.connectGoogleProfile}</p>
                  <Tooltip
                    title={
                      calendarIsConnected ? strings.connectGoogleTooltip : ""
                    }
                    placement="right"
                    arrow
                  >
                    <div
                      style={{
                        width: "fit-content",
                        marginTop: "10px",
                      }}
                    >
                      <StandartButton
                        buttonTitle={strings.connectProfileBtn}
                        action={connectGoogleCalendar}
                        width="100%"
                        disabled={calendarIsConnected}
                      />
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>
          ) : (
            <PublishedPosts profileUserData={profileUserData} />
          )}
        </>
      )}
    </div>
  );
};

export default ClientProfileContent;
