import SpecialistApi from "../api/specialistApi";
import UsersApi from "../api/usersApi";
import { PlaceType } from "../components/CitySelect/CitySelect";

export const generateNumbersArray = (start: number, end: number) => {
  var arrayOfStrings = [];
  for (var i = start; i <= end; i++) {
    arrayOfStrings.push(String(i));
  }
  return arrayOfStrings;
};
export const daysList = generateNumbersArray(1, 31).map((item) => ({
  value: item,
  lable: item,
  color: "black",
}));

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const monthList = generateNumbersArray(1, 12).map((item, index) => {
  return {
    value: item,
    lable: monthNames[index],
  };
});
export const yearsList = generateNumbersArray(1950, 2024).map((item) => ({
  value: item,
  lable: item,
}));
export const updateSpecialistIntroVideo = async (
  video: File,
  specialistId: string,
  token: string
) => {
  const formData = new FormData();
  formData.append("file", video);
  formData.append("specialistId", specialistId);

  const response = await SpecialistApi.uploadIntroVideo(
    specialistId,
    formData,
    token
  );
  return response;
};

export const updateUserAvatar = async (
  avatar: File,
  userId: string,
  token: string
) => {
  const formData = new FormData();
  formData.append("avatar", avatar);

  const response = await UsersApi.updateAvatar(token, formData, userId);

  return response;
};

export const addSpecialistCertificates = async (
  certificates: File[],
  specialistId: string,
  token: string
) => {
  const formData = new FormData();
  for (let i = 0; i < certificates.length; i++) {
    formData.append("files", certificates[i]);
  }
  formData.append("specialistId", specialistId);
  const response = await SpecialistApi.uploadCertificates(
    specialistId,
    formData,

    token
  );
  return response;
};

interface TextInputValues {
  latinName: string;
  latinSurname: string;
  name: string;
  surname: string;
  practics: string;
  experience: string;
}

const isTextInputValueValid = (value: string) => value.trim() !== "";

export const areAllStatesValid = (
  textInputValues: TextInputValues,
  selectedCountry: string,
  selectedCity: PlaceType | null,
  selectedLangs: string | string[]
): boolean => {
  const isNameValid =
    isTextInputValueValid(textInputValues.latinName) &&
    isTextInputValueValid(textInputValues.name);
  const isSurnameValid =
    isTextInputValueValid(textInputValues.latinSurname) &&
    isTextInputValueValid(textInputValues.surname);
  const isPracticsValid = isTextInputValueValid(textInputValues.practics);
  const isExperienceValid = isTextInputValueValid(textInputValues.experience);

  const isCountryValid = isTextInputValueValid(selectedCountry);
  const isCityValid = selectedCity !== null;

  const areLangsValid = (): boolean => {
    if (Array.isArray(selectedLangs)) {
      return selectedLangs.length > 0;
    }

    return isTextInputValueValid(selectedLangs);
  };

  return (
    isNameValid &&
    isSurnameValid &&
    isPracticsValid &&
    isExperienceValid &&
    isCountryValid &&
    isCityValid &&
    areLangsValid()
  );
};

export const calculateAge = (birthDate: Date): number => {
  const currentDate: Date = new Date();
  let age: number = currentDate.getFullYear() - birthDate.getFullYear();
  const currentMonth: number = currentDate.getMonth();
  const birthMonth: number = birthDate.getMonth();

  if (
    currentMonth < birthMonth ||
    (currentMonth === birthMonth && currentDate.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
};

export const formatDate = (date: Date): string => {
  const day: number = date.getDate();
  const month: number = date.getMonth() + 1;
  const year: number = date.getFullYear();

  const formattedDay: string = day < 10 ? "0" + day : "" + day;
  const formattedMonth: string = month < 10 ? "0" + month : "" + month;

  return formattedDay + "." + formattedMonth + "." + year;
};
