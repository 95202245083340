import ky from "ky";
import { network } from "../config";
import {
  MyProjectType,
  Project,
  Roles,
  TypeCompanyEmployee,
} from "../types/Company";
import { TypeNewProject } from "../types/Projects";
import TypeDefaultResponse from "../types/TypeDefaultResponse";
import { SessionPackage } from "../types/TypeSession";
import {
  OverLaps,
  ParamsAvailable,
  TypeSpecialistFilter,
} from "../types/TypeSpecialist";
import { TypeUpdatedUserInfo, TypeUserData } from "../types/TypeUsers";

const { projects } = network;

const baseURL = process.env.REACT_APP_HTTP_CONNECTION_STRING + "projects/";

const getAuthHeader = (token: string) => {
  return { Authorization: `Bearer ${token}` };
};

export default class ProjectApi {
  static async createProject(
    token: string,
    payload: { companyId: string; newProject: TypeNewProject }
  ): Promise<TypeDefaultResponse & { project: Project }> {
    return await ky
      .post(`${baseURL}${projects.createProject}`, {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }

  static async update(
    token: string,
    updateData: TypeUpdatedUserInfo
  ): Promise<TypeDefaultResponse & { project: Project }> {
    return await ky
      .post(`${baseURL}${projects.update}/${updateData._id}`, {
        headers: getAuthHeader(token),
        json: { updateData },
      })
      .then((res) => res.json());
  }

  static async deleteProject(
    token: string,
    noteId: string
  ): Promise<TypeDefaultResponse> {
    return await ky
      .post(`${baseURL}${projects.deleteProject}/${noteId}`, {
        headers: getAuthHeader(token),
        json: { noteId },
      })
      .then((res) => res.json());
  }

  static async getProjectById(
    token: string,
    progectId: string
  ): Promise<TypeDefaultResponse & { project: Project }> {
    return await ky
      .get(`${baseURL}${projects.getProjectById}/${progectId}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async inviteUserToProject(
    token: string,
    paylaod: {
      emails: string[];
      projectId: string;
      role: Roles.DEEFAULT_USER | Roles.SPECIALIST;
    }
  ): Promise<TypeDefaultResponse[]> {
    return await ky
      .post(`${baseURL}${projects.inviteUserToProject}/${paylaod.projectId}`, {
        headers: getAuthHeader(token),
        json: paylaod,
      })
      .then((res) => res.json());
  }
  static async joinProject(
    token: string,
    paylaod: {
      userId: string;
      code: string;
    }
  ): Promise<TypeDefaultResponse> {
    return await ky
      .post(`${baseURL}${projects.joinProject}`, {
        headers: getAuthHeader(token),
        json: paylaod,
      })
      .then((res) => res.json());
  }

  static async removeUserFromProject(
    token: string,
    payload: { userIdToRemove: string; projectId: string }
  ): Promise<TypeDefaultResponse> {
    return await ky
      .post(
        `${baseURL}${projects.removeUserFromProject}/${payload.projectId}`,
        {
          headers: getAuthHeader(token),
          json: payload,
        }
      )
      .then((res) => res.json());
  }

  static async getProjectParticipants(
    token: string,
    progectId: string
  ): Promise<
    TypeDefaultResponse & { participants?: TypeCompanyEmployee[] | null }
  > {
    return await ky
      .get(`${baseURL}${projects.getProjectParticipants}/${progectId}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getUserProjects(
    token: string,
    userId: string,
    limit: number,
    page: number
  ): Promise<
    TypeDefaultResponse & {
      projects?: MyProjectType[];
    }
  > {
    return await ky
      .get(`${baseURL}${projects.getUserProjects}/${userId}/${limit}/${page}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async joinNonPrivateProject(
    token: string,
    userId: string,
    projectId: string
  ): Promise<TypeDefaultResponse> {
    return await ky
      .post(`${baseURL}${projects.joinNonPrivateProject}`, {
        headers: getAuthHeader(token),
        json: { userId, projectId },
      })
      .then((res) => res.json());
  }

  static async getAvailableSessionsInProject(
    token: string,
    userId: string,
    progectId: string
  ): Promise<TypeDefaultResponse & { project: Project }> {
    return await ky
      .get(
        `${baseURL}${projects.getAvailableSessionsInProject}/${userId}/${progectId}`,
        {
          headers: getAuthHeader(token),
        }
      )
      .then((res) => res.json());
  }

  static async getFilteredProjectSpecialists(
    token: string,
    payload: {
      userId: string;
      filters: TypeSpecialistFilter;
      alreadyFetched?: string[];
      projectId: string;
    }
  ): Promise<
    TypeDefaultResponse & {
      paramsAvailable: ParamsAvailable;
      users?: TypeUserData[];
      overlapDays: OverLaps[];
    }
  > {
    return await ky
      .post(`${baseURL}${projects.getFilteredProjectSpecialists}`, {
        json: payload,
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getProjectPackageOfSpecialist(
    token: string,
    projectId: string,
    specialistId: string
  ): Promise<TypeDefaultResponse & { package?: SessionPackage }> {
    return await ky
      .get(
        `${baseURL}${projects.getProjectPackageOfSpecialist}/${specialistId}/${projectId}`,
        {
          headers: getAuthHeader(token),
        }
      )
      .then((res) => res.json());
  }

  static async getMyProjectsWithoutPackage(
    token: string,
    specialistId: string
  ): Promise<TypeDefaultResponse & { projects?: Project[] }> {
    return await ky
      .get(
        `${baseURL}${projects.getMyProjectsWithoutPackage}/${specialistId}`,
        {
          headers: getAuthHeader(token),
        }
      )
      .then((res) => res.json());
  }
}
