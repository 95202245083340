import { Dispatch, SetStateAction, useEffect, useState } from "react";
import SpecialistApi from "../../api/specialistApi";
import {
  Education,
  EducationCertificatesResponse,
  Federation,
  FederationsCertificatesResponse,
  Qualification,
  QualificationCertificatesResponse,
} from "../../types/TypeSpecialist";
import Loader from "../Loader/Loader";
import ModalWindow from "../ModalWindow/ModalWindow";
import s from "./AddCertificatesModal.module.css";
import EducationsCertificates from "./EducationsCertificates/EducationsCertificates";
import FederationsCertificates from "./FederationsCertificates/FederationsCertificates";
import QualificationsCertificates from "./QualificationsCertificates/QualificationsCertificates";

type CertificatesData = {
  education: EducationCertificatesResponse[] | undefined;
  qualification: QualificationCertificatesResponse[] | undefined;
  federation: FederationsCertificatesResponse[] | undefined;
};

type AddCertificatesModal = {
  modalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  certificateType: "education" | "qualification" | "federation";
  specialization: string;
  modalTitle: string;
  specialistId: string;
  setNewCertificate: Dispatch<SetStateAction<CertificatesData>>;
};

const AddCertificatesModal = ({
  modalOpen,
  setModalOpen,
  certificateType,
  specialization,
  modalTitle,
  specialistId,
  setNewCertificate,
}: AddCertificatesModal) => {
  const [selectData, setSelectData] = useState<{
    education?: Education[];
    qualification?: Qualification[];
    federation?: Federation[];
  }>({});

  const [actionLoaders, setActionLoaders] = useState({
    addedCertificate: false,
  });
  const getEducations = async () => {
    const response = await SpecialistApi.getEducations(specialization);
    if (response.status && response.educations) {
      setSelectData((prev) => ({ ...prev, education: response.educations }));
    }
  };
  const getFederations = async () => {
    const response = await SpecialistApi.getFederations(specialization);

    if (response.status && response.federations) {
      setSelectData((prev) => ({ ...prev, federation: response.federations }));
    }
  };
  const getQualifications = async () => {
    const response = await SpecialistApi.getQualifications(specialization);

    if (response.status && response.qualifications) {
      setSelectData((prev) => ({
        ...prev,
        qualification: response.qualifications,
      }));
    }
  };
  useEffect(() => {
    getEducations();
    getFederations();
    getQualifications();
  }, []);

  return (
    <ModalWindow
      isOpen={modalOpen}
      setIsOpen={setModalOpen}
      width="fit-content"
      headTitle={modalTitle}
    >
      {actionLoaders.addedCertificate ? (
        <div className={s.loaderContainer}>
          <Loader size={100} />
        </div>
      ) : certificateType === "federation" ? (
        <FederationsCertificates
          selectData={selectData}
          setActionLoaders={setActionLoaders}
          setModalOpen={setModalOpen}
          specialistId={specialistId}
          specialization={specialization}
          setNewCertificate={setNewCertificate}
        />
      ) : certificateType === "qualification" ? (
        <QualificationsCertificates
          selectData={selectData}
          setActionLoaders={setActionLoaders}
          setModalOpen={setModalOpen}
          specialistId={specialistId}
          specialization={specialization}
          setNewCertificate={setNewCertificate}
        />
      ) : (
        <EducationsCertificates
          selectData={selectData}
          setActionLoaders={setActionLoaders}
          setModalOpen={setModalOpen}
          specialistId={specialistId}
          specialization={specialization}
          setNewCertificate={setNewCertificate}
        />
      )}
    </ModalWindow>
  );
};

export default AddCertificatesModal;
