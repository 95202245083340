import dayjs, { Dayjs } from "dayjs";
import { Dispatch, SetStateAction, useState } from "react";
import SpecialistApi from "../../../api/specialistApi";
import EditIcon from "../../../assets/Profile/cloud-plus.svg";
import { ReactComponent as PlusIcon } from "../../../assets/Profile/specialist/add.svg";
import strings from "../../../localization";
import {
  Education,
  EducationCertificatesResponse,
  Federation,
  FederationsCertificatesResponse,
  Qualification,
  QualificationCertificatesResponse,
} from "../../../types/TypeSpecialist";
import { compressFiles } from "../../../utils/compressionFile";
import Notify, { SuccesNotify } from "../../../utils/toaster";
import Input from "../../Input/Input";
import MuiDatePicker from "../../MuiDatePicker/MuiDatePicker";
import MultipleSelect from "../../MultipleSelect/MultipleSelect";
import StandartButton from "../../StandartButton/StandartButton";
import s from "../AddCertificatesModal.module.css";

type CertificatesData = {
  education: EducationCertificatesResponse[] | undefined;
  qualification: QualificationCertificatesResponse[] | undefined;
  federation: FederationsCertificatesResponse[] | undefined;
};

type FederationsCertificates = {
  selectData: {
    education?: Education[];
    qualification?: Qualification[];
    federation?: Federation[];
  };
  setActionLoaders: Dispatch<SetStateAction<{ addedCertificate: boolean }>>;
  specialistId: string;
  specialization: string;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  setNewCertificate: Dispatch<SetStateAction<CertificatesData>>;
};

type NewEducation = {
  newEducation: {
    educationText: string | undefined;
  };
  educationToSave: {
    file: File | undefined;
    description: string | undefined;
    educationId: string | undefined;
    membershipStart: Date | undefined;
    membershipEnd: Date | undefined;
  };
};

const initialEducation: NewEducation = {
  newEducation: {
    educationText: undefined,
  },
  educationToSave: {
    file: undefined,
    description: undefined,
    educationId: undefined,
    membershipStart: undefined,
    membershipEnd: undefined,
  },
};

const EducationsCertificates = ({
  selectData,
  setActionLoaders,
  specialistId,
  specialization,
  setModalOpen,
  setNewCertificate,
}: FederationsCertificates) => {
  const token = localStorage.getItem("token");
  const [selectValue, setSelectValue] = useState<string>("");
  const [addEducation, setAddEducation] =
    useState<NewEducation>(initialEducation);

  const handleCertificateChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target && event.target.files) {
      const file = event.target.files[0];
      if (file && file.type.includes("pdf")) {
        setAddEducation((prev) => ({
          ...prev,
          educationToSave: {
            ...prev.educationToSave,
            file: file,
          },
        }));
      }
      if (file && !file.type.includes("pdf")) {
        const compressedFile = await compressFiles(file);
        if (compressedFile.file) {
          setAddEducation((prev) => ({
            ...prev,
            educationToSave: {
              ...prev.educationToSave,
              file: compressedFile.file,
            },
          }));
        }
      }
    }
  };

  const createEducation = async () => {
    if (!token) return;
    const payload = {
      educationText: addEducation.newEducation.educationText!,
      specialization: specialization,
    };
    const response = await SpecialistApi.createEducation(token, payload);
    return { status: response.status, educationId: response.education?._id };
  };

  const saveEducation = async () => {
    if (!token) return;
    setActionLoaders((prev) => ({ ...prev, addedCertificate: true }));
    let educationId: string | undefined = undefined;
    if (selectValue === "other") {
      const createEducationResponse = await createEducation();
      educationId = createEducationResponse?.educationId;
    }
    if (selectValue !== "other") {
      educationId = selectValue;
    }

    if (educationId) {
      const formData = new FormData();
      formData.append("file", addEducation.educationToSave.file!);
      formData.append("description", addEducation.educationToSave.description!);
      formData.append("educationId", educationId);
      formData.append(
        "membershipStart",
        addEducation.educationToSave.membershipStart!.toISOString()
      );
      formData.append(
        "membershipEnd",
        addEducation.educationToSave.membershipEnd!.toISOString()
      );
      const mainResponse = await SpecialistApi.uploadEducationCertificates(
        specialistId,
        formData,
        token
      );

      if (mainResponse.status) {
        setNewCertificate((prev) => ({
          ...prev,
          education: prev.education
            ? [...prev.education, mainResponse.certificate]
            : [mainResponse.certificate],
        }));
        SuccesNotify(strings.federationSucces);
        setAddEducation(initialEducation);
        setModalOpen(false);
        setSelectValue("");
        setActionLoaders((prev) => ({ ...prev, addedCertificate: false }));
      }
      if (!mainResponse.status && mainResponse.message) {
        Notify(mainResponse.message);
        setActionLoaders((prev) => ({ ...prev, addedCertificate: false }));
      }
    }
  };
  const validationNewCertificate = () => {
    const educationToSave = addEducation.educationToSave;
    const newEducation = addEducation.newEducation;
    if (!selectValue) return false;
    if (
      !educationToSave.membershipStart ||
      !educationToSave.membershipEnd ||
      !educationToSave.file
    )
      return false;
    if (selectValue === "other" && !newEducation.educationText) return false;
    return true;
  };
  return (
    <div className={`${s.container} ${s.federationContainer}`}>
      {selectData &&
        selectData.education &&
        (selectValue !== "other" ? (
          <MultipleSelect
            data={[
              ...(selectData.education.map((item) => ({
                lable: item.text,
                value: item._id,
              })) ?? []),
              { lable: strings.otherCertificate, value: "other" },
            ]}
            multiplie={false}
            setValue={(value) => setSelectValue(value as string)}
            value={selectValue}
            padding="10px"
            lable={strings.educationTitle}
            margin="0 0 20px 0"
          />
        ) : (
          <div className={s.newFederationBlock}>
            <Input
              inputValue={addEducation.newEducation.educationText ?? ""}
              isVisible
              required
              onChangeInput={(value) =>
                setAddEducation((prev) => ({
                  ...prev,
                  newEducation: {
                    ...prev.newEducation,
                    educationText: value,
                  },
                }))
              }
              lable={strings.educationTitle}
              margin="0 0 20px 0"
            />
          </div>
        ))}
      <Input
        inputValue={addEducation.educationToSave.description ?? ""}
        isVisible
        required
        onChangeInput={(value) =>
          setAddEducation((prev) => ({
            ...prev,
            educationToSave: {
              ...prev.educationToSave,
              description: value,
            },
          }))
        }
        lable={strings.courseTitle}
      />
      <div className={s.dateBlock}>
        <span className={s.secondLable}>
          {strings.federationDatesDescription}
        </span>
        <div className={s.dateInputBlock}>
          <div className={s.dateInput}>
            <MuiDatePicker
              value={
                addEducation.educationToSave.membershipStart &&
                dayjs(addEducation.educationToSave.membershipStart)
              }
              setValue={(value: Dayjs | null) =>
                setAddEducation((prev) => ({
                  ...prev,
                  educationToSave: {
                    ...prev.educationToSave,
                    membershipStart: value?.toDate(),
                  },
                }))
              }
            />
          </div>
          <div className={s.dateInput}>
            <MuiDatePicker
              value={
                addEducation.educationToSave.membershipEnd &&
                dayjs(addEducation.educationToSave.membershipEnd)
              }
              setValue={(value: Dayjs | null) =>
                setAddEducation((prev) => ({
                  ...prev,
                  educationToSave: {
                    ...prev.educationToSave,
                    membershipEnd: value?.toDate(),
                  },
                }))
              }
            />
          </div>
        </div>
      </div>
      <div className={s.uploadCertificateBlock}>
        <span className={s.headLable}>{strings.certificatesTitle}</span>
        <span className={s.secondLable}>{strings.certificateDescr}</span>
        <div className={s.uploadFilesActionBlock}>
          {addEducation.educationToSave.file && (
            <div>{addEducation.educationToSave.file.name}</div>
          )}
          <label className={s.fileInputBlock}>
            <input
              style={{ display: "none" }}
              type="file"
              accept=".pdf, image/*"
              multiple
              onChange={handleCertificateChange}
            />
            {addEducation.educationToSave.file ? (
              <span className={s.updateAvatarBtn} role="button">
                <img src={EditIcon} alt="" />
                <span>{strings.replace}</span>
              </span>
            ) : (
              <div className={s.addCertificateBtn}>
                <PlusIcon />
                <span>{strings.addCertificate}</span>
              </div>
            )}
          </label>
        </div>
      </div>
      <StandartButton
        action={saveEducation}
        buttonTitle={strings.saveCertificateBtn}
        width="100%"
        disabled={!validationNewCertificate()}
      />
    </div>
  );
};

export default EducationsCertificates;
